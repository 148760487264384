import { Component, default as React } from 'react';
import { Application } from './Application';
import './AppsSorted.scss';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import { IApplicationOperations } from './Apps';

interface IAppsSortedProps {
  applicationOperations: IApplicationOperations;
  applications: ILocationApplication[] | null;
  userProfiles: string[];
}

class AppsSorted extends Component<IAppsSortedProps, any> {
  constructor(props: IAppsSortedProps) {
    super(props);
  }

  public render = () => (
    <div className="AZList ms-slideDownIn10">
      {this.props.applications &&
        this.getAppListChunks(3).map((appChunk: ILocationApplication[], index: number) => (
          <div key={index} className="Column">
            {appChunk.map(a => (
              <Application
                userProfiles={this.props.userProfiles}
                application={a}
                key={a.id}
                applicationOperations={this.props.applicationOperations}
              />
            ))}
          </div>
        ))}
    </div>
  );

  private getAppListChunks = (numberOfChunks: number): ILocationApplication[][] => {
    const chunks: ILocationApplication[][] = [];
    const appsPerChunk = Math.ceil(this.props.applications!.length / numberOfChunks);
    for (let i = 0; i < numberOfChunks - 1; i++) {
      chunks.push(this.props.applications!.slice(i * appsPerChunk, (i + 1) * appsPerChunk));
    }
    chunks.push(this.props.applications!.slice((numberOfChunks - 1) * appsPerChunk));
    return chunks;
  };
}

export default AppsSorted;
