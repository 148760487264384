import './polyfills';
import { initializeIcons } from '@uifabric/icons';
import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './app/routes/MainApp/MainApp';
import * as serviceWorker from './serviceWorker';
import { auth } from './shared/auth/auth';
import WebFont from 'webfontloader';
import { initialiseAnalyticsEngine } from './shared/analytics/analytics';
import './index.scss';

WebFont.load({
  google: {
    families: ['Raleway:400,500,600,800', 'sans-serif']
  }
});

const initialiseApp = async () => {
  if (await auth.ensureAppAuthenticated()) {
    initialiseAnalyticsEngine();
    ReactDOM.render(<MainApp />, document.getElementById('root'));
  }
};

initializeIcons();
initialiseApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
