import React, { Component } from 'react';
import { SearchBox } from 'office-ui-fabric-react/lib-commonjs/SearchBox';
import { makeSearchAllSitesUrl } from '../../../shared/config/config';

interface ISearchAllSitesProps {
  className: string;
}

interface ISearchAllSitesState {
  searchPhrase: string;
}

export default class SearchAllSites extends Component<ISearchAllSitesProps, ISearchAllSitesState> {
  constructor(props: ISearchAllSitesProps) {
    super(props);
    this.state = {
      searchPhrase: ''
    };
  }

  public render = () => (
    <SearchBox
      className={`SearchAllSitesField ${this.props.className}`}
      placeholder="Search all sites"
      onSearch={this.searchAllSites}
      autoComplete="off"
      value={this.state.searchPhrase}
      onChange={this.onSearchPhraseChanged}
    />
  );

  private searchAllSites = (searchPhrase: string) => {
    const url = makeSearchAllSitesUrl(searchPhrase);
    window.location.href = url;
  };

  private onSearchPhraseChanged = (searchPhrase: string) => {
    this.setState({ searchPhrase });
  };
}
