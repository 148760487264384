import { debounce } from 'lodash';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import Dialog, { DialogFooter, DialogType } from 'office-ui-fabric-react/lib-commonjs/Dialog';
import { Icon } from 'office-ui-fabric-react/lib-commonjs/Icon';
import { Image } from 'office-ui-fabric-react/lib-commonjs/Image';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import { SearchBox } from 'office-ui-fabric-react/lib-commonjs/SearchBox';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import './AppEditList.scss';
import { AppSearchResult } from './AppSearchResult';

interface IAppEditListProps {
  availableApps: ILocationApplication[];
  favoriteApps: ILocationApplication[];
  favoriteAppsListLimit: number;
  mainAppUrl: string | undefined;
  searchPhrase: string;
  isSaving: boolean;
  onSearchPhraseChange: (searchPhrase: string) => void;
  onAppListChange: (reorderedItems: ILocationApplication[]) => void;
  saveFavoriteApps: () => void;
  revertFavoriteApps: () => void;
  resetFavoriteApps: () => void;
}

interface IAppEditListState {
  isDragging: boolean;
  isResetFavouriteAppsDialogHidden: boolean;
}

class AppEditList extends Component<IAppEditListProps, IAppEditListState> {
  constructor(props: IAppEditListProps) {
    super(props);
    this.state = {
      isResetFavouriteAppsDialogHidden: true,
      isDragging: false
    };
  }

  public render = () => (
    <div>
      <div className="PrimaryTitle">Edit Menu</div>

      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.props.favoriteApps.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {provided2 => (
                    <div ref={provided2.innerRef} {...provided2.draggableProps} {...provided2.dragHandleProps} className="AppEditItem">
                      {this.renderApplication(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {this.hasFavouriteAppCountReachedTheLimit() && <div className="AppLimitReached">You have reached the menu limit</div>}
      {!this.hasFavouriteAppCountReachedTheLimit() && (
        <>
          <SearchBox
            placeholder="Add Application"
            value={this.props.searchPhrase}
            autoComplete="off"
            onChange={this.onSearchPhraseChanged}
            className="AppSearchBox"
          />
          <AppSearchResult
            availableApps={this.props.availableApps}
            favoriteApps={this.props.favoriteApps}
            searchPhrase={this.props.searchPhrase}
            mainAppUrl={this.props.mainAppUrl}
            onItemSelected={this.onNewApplicationSelected}
          />
        </>
      )}
      <div className="ActionBar">
        <PrimaryButton disabled={this.props.isSaving || !this.isFormValid()} onClick={this.props.saveFavoriteApps}>
          Save
        </PrimaryButton>
        <DefaultButton disabled={this.props.isSaving} onClick={this.props.revertFavoriteApps} className="Cancel">
          Cancel
        </DefaultButton>
      </div>
      <Link disabled={this.props.isSaving} className="ResetLink" onClick={this.openResetFavouriteAppsDialog}>
        Reset Application List
      </Link>
      <Dialog
        className="ResetDialog"
        hidden={this.state.isResetFavouriteAppsDialogHidden}
        onDismiss={this.closeResetFavouriteAppsDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Reset Application List',
          subText: 'Reset will remove the changes you have made to the applications list. Are you sure you want to do this?'
        }}
        modalProps={{
          titleAriaId: 'myLabelId',
          subtitleAriaId: 'mySubTextId',
          isBlocking: true
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={this.resetFavouriteApps} text="Reset" />
          <DefaultButton onClick={this.closeResetFavouriteAppsDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );

  private onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.reorder(this.props.favoriteApps, result.source.index, result.destination.index);
  };

  private openResetFavouriteAppsDialog = () => this.setState({ isResetFavouriteAppsDialogHidden: false });

  private closeResetFavouriteAppsDialog = () => this.setState({ isResetFavouriteAppsDialogHidden: true });

  private resetFavouriteApps = () => {
    this.props.resetFavoriteApps();
    this.closeResetFavouriteAppsDialog();
  };

  private isFormValid = () => !!this.props.favoriteApps.length;

  private reorder = (list: ILocationApplication[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.props.onAppListChange(result);
  };

  private hasFavouriteAppCountReachedTheLimit = () =>
    this.props.favoriteApps.filter((fa: ILocationApplication) => !fa.isArchived).length >= this.props.favoriteAppsListLimit;

  private onNewApplicationSelected = (application: ILocationApplication) => {
    const appIds = this.props.favoriteApps.map(a => a.id);
    if (appIds.indexOf(application.id) < 0) {
      this.props.onSearchPhraseChange('');
      this.props.onAppListChange([...this.props.favoriteApps, application]);
    }
  };

  /* tslint:disable-next-line */
  private onSearchPhraseChanged = debounce((searchPhrase: string) => {
    this.props.onSearchPhraseChange(searchPhrase);
  }, 100);

  private readonly renderApplication = (app: ILocationApplication) => (
    <div key={app.id}>
      <Icon title="Drag this application to change order" iconName="GlobalNavButton" className="DragIcon" />
      <Icon title="Remove this application" onClick={this.removeApp(app)} iconName="ChromeClose" className="RemoveButton" />
      <div className="IconAndTitle">
        <Image src={app!.iconUrl} width={32} height={32} />
        {app!.appName}
      </div>
    </div>
  );

  private removeApp = (appToRemove: ILocationApplication) => () => {
    const reducedItems = this.props.favoriteApps!.filter((item: ILocationApplication) => item !== appToRemove);
    this.props.onAppListChange(reducedItems);
  };
}

export default AppEditList;
