import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { IFeatureToShow } from '../IFeatureToShow';

export const AppSearchFeature: IFeatureToShow = {
  getSelector: () => '#AppSearchBox',
  directionalHint: DirectionalHint.rightCenter,
  title: 'Search',
  description: `Use this search box to find apps. As you start typing the search results will be displayed below. Search results include the app's name description and tags for matching results.`,
  featureDidShowUp: () => {
    const element = document.querySelector('#AppSearchBox');
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};
