import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { MaximumLargeScreenWidth, IFeatureToShow, IFeatureHost } from '../IFeatureToShow';
import { AppTabKeys } from '../../../routes/MainApp/MainApp';

const getElement = (host: IFeatureHost) => {
  const elementSelector =
    host.activeTab === AppTabKeys.Category
      ? window.matchMedia(`(max-width: ${MaximumLargeScreenWidth}px)`).matches
        ? `.ByCategory .OneColumnList .AppAddRemoveButton`
        : `.ByCategory .ThreeColumnList .AppAddRemoveButton`
      : '.AZList .Column .AppAddRemoveButton';
  return document.querySelector<HTMLElement>(elementSelector);
};

export const AddRemoveAppButtonFeature: IFeatureToShow = {
  getSelector: getElement,
  directionalHint: DirectionalHint.topCenter,
  title: 'Add/Remove app from side draw',
  description: `Click the ✚ to add the app to your launcher. A ✔ is shown if the app is already in your launcher. Click the ✔ to remove the app.`,
  featureDidShowUp: (host: IFeatureHost) => {
    const element = getElement(host);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};
