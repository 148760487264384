import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { EventBus, EventNames } from '../../../../shared/eventbus/eventbus';
import { MinimumLargeScreenWidth, IFeatureToShow } from '../IFeatureToShow';

const getSelector = () =>
  window.matchMedia(`(min-width: ${MinimumLargeScreenWidth}px)`).matches ? '.SearchAllSitesField.LargeScreen' : '.SearchAllSitesField.SmallScreen';

export const SearchAllSitesFeature: IFeatureToShow = {
  getSelector,
  directionalHint: DirectionalHint.bottomCenter,
  title: 'Search all sites',
  description: `Use this search box to search across Spire, Kweb, School Portals and more.`,
  featureDidShowUp: () => {
    const element = document.querySelector(getSelector());
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    if (!window.matchMedia(`(min-width: ${MinimumLargeScreenWidth}px)`).matches) {
      EventBus.emit(EventNames.MakeSureGlobalSearchIsVisible);
    }
  }
};
