import { ILocationApplication, CategoryViewModel } from '../../../shared/api/applications/applications';

export class CategoryWithApps {
  private category: CategoryViewModel;
  private apps: ILocationApplication[] = [];

  constructor(category: CategoryViewModel) {
    this.category = category;
  }

  public get id() {
    return this.category.id;
  }

  public get name() {
    return this.category.name;
  }

  public get applications() {
    return this.apps;
  }

  public get appCount() {
    return this.apps.length;
  }

  public get isCollapsed() {
    return this.category.isCollapsed;
  }

  public appendApp(application: ILocationApplication) {
    this.applications.push(application);
  }
}
