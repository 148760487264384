import { Image, ImageFit } from 'office-ui-fabric-react/lib-commonjs/Image';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import { Component, default as React } from 'react';
import './Application.scss';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import { IconButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { reportAppUsage } from '../../../shared/analytics/analytics';
import { IApplicationOperations } from './Apps';
import { TooltipHost, TooltipDelay } from 'office-ui-fabric-react/lib-commonjs/Tooltip';

interface IApplicationProps {
  applicationOperations: IApplicationOperations;
  application: ILocationApplication;
  userProfiles: string[];
}

const ToggleDescriptionButton = (props: { appName: string; toggleDescription: () => void }) => (
  <TooltipHost
    content="Toggle description"
    calloutProps={{
      gapSpace: 0
    }}
  >
    <IconButton
      className="AppDescriptionIcon"
      iconProps={{
        iconName: 'Info'
      }}
      onClick={props.toggleDescription}
    />
  </TooltipHost>
);

const AppDescription = (props: { application: ILocationApplication }) => (
  <div className="AppDescriptionContainer ms-slideDownIn20">
    <div className="AppDescription">{props.application.description}</div>
    {props.application.moreInformationUrl && (
      <div className="MoreInformationContainer">
        <Link href={props.application.moreInformationUrl}>More information</Link>
      </div>
    )}
  </div>
);

const RemoveAppFromFavouritesButton = (props: { appName: string; toggleBelongingnessToFavorites: () => void }) => (
  <TooltipHost
    delay={TooltipDelay.long}
    content="App exists in your launcher. Click to remove."
    calloutProps={{
      gapSpace: 0
    }}
  >
    <IconButton
      className="AppAddRemoveButton"
      iconProps={{
        iconName: 'CheckMark'
      }}
      onClick={props.toggleBelongingnessToFavorites}
    />
  </TooltipHost>
);

const AddAppToFavouritesButton = (props: {
  appName: string;
  applicationOperations: IApplicationOperations;
  toggleBelongingnessToFavorites: () => void;
}) => (
  <TooltipHost
    delay={TooltipDelay.long}
    content={
      props.applicationOperations.canAddToFavorites()
        ? 'Add this app to your app launcher'
        : 'Your app launcher is full. You will need to remove apps before you can add more.'
    }
    calloutProps={{
      gapSpace: 0
    }}
  >
    <IconButton
      className="AppAddRemoveButton"
      iconProps={{
        iconName: 'Add'
      }}
      onClick={props.toggleBelongingnessToFavorites}
      disabled={!props.applicationOperations.canAddToFavorites()}
    />
  </TooltipHost>
);

export class Application extends Component<IApplicationProps, {}> {
  constructor(props: IApplicationProps) {
    super(props);
  }

  public render = () => (
    <>
      <div className="AppItem" key={this.props.application.id}>
        <Link href={this.props.application.url} title={this.props.application.appName} onClick={this.onAppClick(this.props.application)}>
          <Image src={this.props.application.iconUrl} width={32} height={32} imageFit={ImageFit.cover} />
          <span className="AppName">{this.props.application.appName}</span>
        </Link>
        {this.props.application.description && (
          <ToggleDescriptionButton appName={this.props.application.appName} toggleDescription={this.toggleDescription} />
        )}
        {this.props.applicationOperations.isFavorite(this.props.application) && (
          <RemoveAppFromFavouritesButton
            appName={this.props.application.appName}
            toggleBelongingnessToFavorites={this.toggleBelongingnessToFavorites}
          />
        )}
        {!this.props.applicationOperations.isFavorite(this.props.application) && (
          <AddAppToFavouritesButton
            appName={this.props.application.appName}
            applicationOperations={this.props.applicationOperations}
            toggleBelongingnessToFavorites={this.toggleBelongingnessToFavorites}
          />
        )}
      </div>
      {this.props.application.isDescriptionVisible && <AppDescription application={this.props.application} />}
    </>
  );

  private toggleDescription = () => this.props.applicationOperations.onToggleDescription(this.props.application);

  private toggleBelongingnessToFavorites = () => this.props.applicationOperations.onToggleBelongingnessToFavorites(this.props.application);

  private readonly onAppClick = (app: ILocationApplication) => async () => {
    await reportAppUsage(app, this.props.userProfiles, 'MainApp');
    return true;
  };
}
