import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { IFeatureToShow } from '../IFeatureToShow';

const getSelector = () => '#SideDrawEditButton';

export const SideDrawEditButtonFeature: IFeatureToShow = {
  getSelector,
  directionalHint: DirectionalHint.topCenter,
  title: 'Side Draw Edit',
  description: `You can edit this list of apps to suit your needs. Click Edit Menu to add, remove and reorder your list of apps.`,
  featureDidShowUp: () => {
    const element = document.querySelector(getSelector());
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};
