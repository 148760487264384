import React from 'react';
import { filterApplications, ILocationApplication } from '../../../shared/api/applications/applications';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import './AppSearchResult.scss';

interface IAppSearchResultProps {
  searchPhrase: string;
  availableApps: ILocationApplication[] | null;
  favoriteApps: ILocationApplication[] | null;
  mainAppUrl: string | undefined;
  onItemSelected: (application: ILocationApplication) => void;
}

export const AppSearchResult = (props: IAppSearchResultProps) => {
  const filteredApps = filterApplications(props.availableApps, props.searchPhrase).filter(
    a => props.favoriteApps!.map(fa => fa.id).indexOf(a.id) < 0
  );
  const itemClicked = (application: ILocationApplication) => () => props.onItemSelected(application);
  return (
    <>
      {!filteredApps.length && props.searchPhrase && (
        <div className="SearchResultsBox">
          <div className="SearchResultsTitle">No BCE Applications Found</div>
          <div className="SearchResults NoResults">
            <Link href={props.mainAppUrl}>View All BCE Applications</Link>
          </div>
        </div>
      )}
      {!!filteredApps.length && props.searchPhrase && (
        <div className="SearchResultsBox">
          <div className="SearchResultsTitle">BCE Applications</div>
          <div className="SearchResults">
            {filteredApps.map(a => (
              <div className="SearchResultItem" key={a.id} onClick={itemClicked(a)}>
                {a.appName}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
