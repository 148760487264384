import { delay } from '../../util/delay';

export interface IWorkTrackerOptions {
  minDelayInMs?: number;
  startCompleted: boolean;
}

export class WorkTracker {
  public isComplete: boolean;
  private activePromises: number;
  private subscribers: Array<(isComplete: boolean) => void> = [];

  constructor(private options: IWorkTrackerOptions = { minDelayInMs: 200, startCompleted: false }) {
    this.isComplete = options.startCompleted;
    this.activePromises = 0;
  }

  public subscribe(callback: (isComplete: boolean) => void) {
    this.subscribers.push(callback);
  }

  public unsubscribe(callback: (isComplete: boolean) => void) {
    this.subscribers = this.subscribers.filter(s => s !== callback);
  }

  public readonly queueTrackingAndChain = <T>(promise: Promise<T>) => {
    this.track(promise);
    return promise;
  };

  public readonly track = async <T>(promise: Promise<T>) => {
    this.beginTrack();

    try {
      await (this.options.minDelayInMs ? Promise.all([promise, delay(this.options.minDelayInMs)]) : promise);
    } finally {
      // Errors should not cause a loss of accurate completion status tracking
      this.endTrack();
    }
  };

  private readonly beginTrack = () => {
    this.updateCompleteStatus(1);
  };

  private readonly endTrack = () => {
    this.updateCompleteStatus(-1);
  };

  private readonly updateCompleteStatus = (promiseCountChange: number) => {
    this.activePromises = Math.max(0, this.activePromises + promiseCountChange);
    this.isComplete = this.activePromises === 0;
    this.subscribers.forEach(s => s(this.isComplete));
  };
}
