import { getConfig } from '../config/config';
import { toast } from 'react-toastify';

const logInfo = (info: any) => {
  if (getConfig().environment === 'local' || getConfig().environment === 'Dev') {
    info = typeof info === 'string' ? `${getLogPrefix()}${info}` : info;
    console.log(info);
  }
};

const logWarning = (warning: any) => {
  warning = typeof warning === 'string' ? `${getLogPrefix()}${warning}` : warning;
  console.warn(warning);
};

const logError = (error: any) => {
  error = typeof error === 'string' ? `${getLogPrefix()}${error}` : error;
  console.error(error);
};

const reportUserError = (message: string, errorId: number | string) => {
  logError('Error: ' + message);
  if (!getConfig().isWidget) {
    toast.error(message, { toastId: errorId });
  }
};

const getLogPrefix = () => {
  const windowName = window === window.parent ? 'top' : window.frameElement && window.frameElement.id;
  return `${windowName || 'unknown frame'}: `;
};

export { logInfo, logWarning, logError, reportUserError };
