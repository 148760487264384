import { DefaultButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { Panel } from 'office-ui-fabric-react/lib-commonjs/Panel';
import React, { Component } from 'react';
import * as Applications from '../../../shared/api/applications/applications';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import * as UserPreferences from '../../../shared/api/applications/userPreferences';
import * as Configuration from '../../../shared/api/configuration/configuration';
import { UserFavoriteAppDto } from '../../../shared/api/models';
import * as Profiles from '../../../shared/api/profiles/profiles';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import AppEditList from '../AppList/AppEditList';
import AppNavList from '../AppList/AppNavList';
import { auth } from '../../../shared/auth/auth';
import { logError, logInfo } from '../../../shared/logging/logging';
import { initialiseAnalyticsEngine } from '../../../shared/analytics/analytics';
import './WidgetApp.scss';
import { getConfig, fetchConfig } from '../../../shared/config/config';
import { EventBus, EventNames } from '../../../shared/eventbus/eventbus';

interface IWidgetAppMainProps {
  className?: string;
  linkOnlyMode: boolean;
  loginReturnUrl?: string;
  accessToken?: string;
  ensureAuthenticated: boolean;
  loginHint: string;
}

interface IWidgetAppState {
  isOpen: boolean;
  applications: ILocationApplication[];
  preEditFavouriteApps: ILocationApplication[];
  favouriteApps: ILocationApplication[];
  favouriteAppsListLimit: number;
  userProfiles: string[];
  isInEditMode: boolean;
  searchPhrase: string;
  isSaving: boolean;
  mainAppUrl: string | undefined;
  workTracker: WorkTracker;
}

class WidgetApp extends Component<IWidgetAppMainProps, IWidgetAppState> {
  constructor(props: IWidgetAppMainProps) {
    super(props);
    this.state = {
      isOpen: false,
      applications: [],
      preEditFavouriteApps: [],
      favouriteApps: [],
      userProfiles: [],
      isInEditMode: false,
      searchPhrase: '',
      isSaving: false,
      mainAppUrl: undefined,
      favouriteAppsListLimit: 0,
      workTracker: new WorkTracker()
    };
  }

  public componentDidMount = async () => {
    if (this.isWidgetButtonWorkingAsHyperlinkToMainApp()) {
      await fetchConfig();
      this.setState({ mainAppUrl: getConfig().mainAppUrl });
    } else {
      if (this.props.ensureAuthenticated) {
        await this.ensureAuthenticated(this.props.loginHint, this.props.loginReturnUrl);
      }
      this.setState({ mainAppUrl: getConfig().mainAppUrl });
    }
    EventBus.subscribe(EventNames.FavouriteAppListModified, this.onFavouriteAppsModified);
    EventBus.subscribe(EventNames.HideSideDraw, this.hidePanel);
    EventBus.subscribe(EventNames.OpenSideDraw, this.showPanel);

    document.addEventListener('keyup', this.onGlobalKeyUp);
  };

  public componentWillUnmount = () => {
    EventBus.unsubscribe(EventNames.FavouriteAppListModified, this.onFavouriteAppsModified);
    EventBus.unsubscribe(EventNames.HideSideDraw, this.hidePanel);
    EventBus.unsubscribe(EventNames.OpenSideDraw, this.showPanel);

    document.removeEventListener('keyup', this.onGlobalKeyUp);
  };

  public render() {
    const panelStyles = {
      overlay: {
        background: 'rgba(0, 0, 0, 0.4) !important'
      }
    };

    return (
      <div className="WidgetApp">
        <DefaultButton onClick={this.handleWidgetButtonClick} className={`WidgetAppButton ${this.props.className}`}>
          <span className="WidgetIcon">&#59029;</span>
          <span className="WidgetName">BCE Apps</span>
        </DefaultButton>
        <Panel isOpen={this.state.isOpen} isLightDismiss={true} onDismiss={this.hidePanel} styles={panelStyles} className="WidgetAppPanel">
          <LoadingPanel workTracker={this.state.workTracker} style={{ minHeight: '20rem' }}>
            {this.state.isInEditMode ? (
              <AppEditList
                favoriteApps={this.state.favouriteApps}
                availableApps={this.state.applications}
                favoriteAppsListLimit={this.state.favouriteAppsListLimit}
                searchPhrase={this.state.searchPhrase}
                mainAppUrl={this.state.mainAppUrl}
                isSaving={this.state.isSaving}
                onSearchPhraseChange={this.onSearchPhraseChanged}
                onAppListChange={this.onAppListChanged}
                saveFavoriteApps={this.saveFavoriteApps}
                revertFavoriteApps={this.revertFavoriteApps}
                resetFavoriteApps={this.resetFavoriteApps}
              />
            ) : (
              <AppNavList
                userProfiles={this.state.userProfiles}
                favoriteApps={this.state.favouriteApps}
                enterEditMode={this.enterEditMode}
                mainAppUrl={this.state.mainAppUrl}
              />
            )}
          </LoadingPanel>
        </Panel>
      </div>
    );
  }

  private ensureAuthenticated = async (loginHint: string, loginReturnUrl?: string) => {
    if (await this.state.workTracker.queueTrackingAndChain(auth.ensureWidgetAuthenticated(loginHint, loginReturnUrl))) {
      initialiseAnalyticsEngine();
    } else {
      logError('SideDraw: authentication failed');
    }
  };

  private loadData = async (accessToken?: string) => {
    if (accessToken) {
      logInfo('Access Token available - ' + accessToken);
      await Promise.all([this.loadMyProfiles(accessToken), this.loadMyApplications(accessToken)]);
    } else {
      logInfo('Getting Access Token from scope - ' + auth.getApplicationScopes());
      auth.getAccessToken(auth.getApplicationScopes()).then(async token => {
        logInfo('Generated Access Token is - ' + token);
        await Promise.all([this.loadMyProfiles(token), this.loadMyApplications(token)]);
      });
    }
  };

  private async loadMyProfiles(accessToken?: string) {
    const profilesResponse = await Profiles.getMine(accessToken);
    if (profilesResponse.success) {
      this.setState({ userProfiles: profilesResponse.result!.map(p => p.profileCode) });
    }
  }

  private async loadMyApplications(accessToken?: string) {
    const [myAppsResponse, favouriteAppsListLimitResponse] = await this.state.workTracker.queueTrackingAndChain(
      Promise.all([Applications.getMineFlat(accessToken), Configuration.get(accessToken)])
    );
    if (myAppsResponse.success) {
      const apps = myAppsResponse.result!.applications || [];
      this.setState({ favouriteApps: apps });
    }
    if (favouriteAppsListLimitResponse.success) {
      this.setState({ favouriteAppsListLimit: favouriteAppsListLimitResponse.result!.sideDrawAppCountLimit });
    }
    return myAppsResponse.success && favouriteAppsListLimitResponse.success;
  }

  private async loadApplications(accessToken?: string) {
    const [appsResponse, userPrefsResponse, favouriteAppsListLimitResponse] = await this.state.workTracker.queueTrackingAndChain(
      Promise.all([Applications.getManyFlat(accessToken), UserPreferences.get(accessToken), Configuration.get(accessToken)])
    );

    if (appsResponse.success) {
      const apps = appsResponse.result!.applications || [];
      this.setState({ applications: apps });

      if (userPrefsResponse.success) {
        const favAppDtos = userPrefsResponse.result ? userPrefsResponse.result.favoriteApps : [];
        this.setState({ favouriteApps: this.favAppDtosToViewModel(favAppDtos) });
      }
    }

    if (favouriteAppsListLimitResponse.success) {
      this.setState({ favouriteAppsListLimit: favouriteAppsListLimitResponse.result!.sideDrawAppCountLimit });
    }

    return appsResponse.success && userPrefsResponse.success && favouriteAppsListLimitResponse.success;
  }

  private favAppDtosToViewModel = (favAppDtos: UserFavoriteAppDto[]) => {
    return favAppDtos
      .map(
        (fav: UserFavoriteAppDto): ILocationApplication =>
          this.state.applications.find(
            (a: ILocationApplication): boolean => a.appId === fav.appId && a.locationCode === fav.locationCode
          ) as ILocationApplication
      )
      .filter(a => !!a);
  };

  private onSearchPhraseChanged = (searchPhrase: string) => this.setState({ searchPhrase });

  private enterEditMode = async () => {
    const result = await Applications.getManyFlat(this.props.accessToken);
    if (result.success) {
      const apps = result.result!.applications || [];
      this.setState({
        applications: apps,
        isInEditMode: true,
        preEditFavouriteApps: this.state.favouriteApps.slice()
      });
    }
  }

  private revertFavoriteApps = () =>
    this.setState({
      isInEditMode: false,
      searchPhrase: '',
      favouriteApps: this.state.preEditFavouriteApps,
      preEditFavouriteApps: []
    });

  private saveFavoriteApps = async () => {
    this.setState({ isSaving: true });
    const favApps = this.state.favouriteApps.map(
      (fa: ILocationApplication): UserFavoriteAppDto => ({ appId: fa.appId, locationCode: fa.locationCode })
    );
    const result = await this.state.workTracker.queueTrackingAndChain(UserPreferences.update({ favoriteApps: favApps }, this.props.accessToken));
    this.setState({ isSaving: false, isInEditMode: !result.success, searchPhrase: '' });
    if (result.success) {
      this.notifyAboutFavouriteAppListBeingModified();
    }
  };

  private resetFavoriteApps = async () => {
    this.setState({ isSaving: true });
    const resetListResult = await this.state.workTracker.queueTrackingAndChain(UserPreferences.reset(this.props.accessToken));
    const loadDefaultAppsResult = await this.loadApplications(this.props.accessToken);
    const allRequestsSucceeded = resetListResult.success && loadDefaultAppsResult;
    this.setState({ isSaving: false, isInEditMode: !allRequestsSucceeded, searchPhrase: '' });
    if (allRequestsSucceeded) {
      this.notifyAboutFavouriteAppListBeingModified();
    }
  };

  private notifyAboutFavouriteAppListBeingModified() {
    EventBus.emit(EventNames.FavouriteAppListModified, this.state.favouriteApps);
  }

  private onFavouriteAppsModified = (newFavAppDtos: UserFavoriteAppDto[]) =>
    this.setState({
      isInEditMode: false,
      searchPhrase: '',
      favouriteApps: this.favAppDtosToViewModel(newFavAppDtos),
      preEditFavouriteApps: []
    });

  private onAppListChanged = (changedListOfApps: ILocationApplication[]) => this.setState({ favouriteApps: changedListOfApps });

  private onGlobalKeyUp = (ev: KeyboardEvent) => {
    if (this.state.isOpen && ev.keyCode === 27 /* Escape key */) {
      this.hidePanel();
    }
  };

  private handleWidgetButtonClick = (): void => {
    if (this.isWidgetButtonWorkingAsHyperlinkToMainApp()) {
      if (this.state.mainAppUrl !== undefined) {
        window.location.href = this.state.mainAppUrl;
      }
    } else {
      this.showPanel();
      EventBus.emit(EventNames.UserOpenedSideDraw);
    }
    // only loading apps once
    if (this.state.favouriteApps.length === 0) {
      this.loadData(this.props.accessToken);
    }
  };

  private showPanel = (): void => {
    this.setState({ isOpen: true });
  };

  private hidePanel = (): void => {
    this.setState({ isOpen: false });
  };

  private isWidgetButtonWorkingAsHyperlinkToMainApp = () => this.props.linkOnlyMode;
}

export default WidgetApp;
