import React, { Component } from 'react';
import AdminAppList from '../AdminAppList/AdminAppList';
import RouteLink, { RouteLinkType } from '../CustomRoutes/RouteLink';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib-commonjs/Pivot';
import AdminCategoryList from '../Categories/AdminCategoryList';
import AdminConfiguration from '../AdminConfiguration/AdminConfiguration';
import AdminDefaultProfileApps from '../AdminDefaultProfileApps/AdminDefaultProfileApps';

class Admin extends Component<any, any> {
  public render() {
    return (
      <div>
        <Pivot>
          <PivotItem linkText="Applications">
            <RouteLink to="/admin/applications/new" linkType={RouteLinkType.DefaultButton} style={{ marginTop: '1rem' }}>
              Add a New Application
            </RouteLink>
            <div style={{ marginTop: '1rem' }}>
              <AdminAppList />
            </div>
          </PivotItem>
          <PivotItem linkText="Categories">
            <RouteLink to="/admin/categories/new" linkType={RouteLinkType.DefaultButton} style={{ marginTop: '1rem' }}>
              Add a New Category
            </RouteLink>
            <div style={{ marginTop: '1rem' }}>
              <AdminCategoryList />
            </div>
          </PivotItem>
          <PivotItem linkText="Default Profile Apps">
            <div style={{ marginTop: '1rem' }}>
              <AdminDefaultProfileApps />
            </div>
          </PivotItem>
          <PivotItem linkText="Settings">
            <div style={{ marginTop: '1rem' }}>
              <AdminConfiguration />
            </div>
          </PivotItem>
        </Pivot>
      </div>
    );
  }
}

export default Admin;
