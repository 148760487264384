import { auth } from '../auth/auth';
import { getConfig } from '../config/config';
import { logError,logInfo, reportUserError } from '../logging/logging';

enum StatusCode {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500
}

export interface IApiResponse<TResult> {
  success: boolean;
  result?: TResult;
}

const ContentTypeKey = 'content-type';
const defaultHeaders: { [key: string]: string } = {};
defaultHeaders[ContentTypeKey] = 'application/json';
const GenericErrorMessage = 'An unknown error occurred. Please try again or contact your administrator.';

const makeUri = (apiBaseUrl: string, path: string) => `${apiBaseUrl}/${path}`;

const makeHeaders = (customHeaders: any, isItMultipartForm: boolean): { [key: string]: string } => {
  const headers = {
    ...defaultHeaders,
    ...customHeaders
  };
  if (isItMultipartForm) {
    delete headers[ContentTypeKey];
  }
  return headers;
};

const formatData = (data: any, files: File[] | undefined) => {
  const form = data ? JSON.stringify(data) : undefined;
  const isItMultipartForm = !!files;
  if (!isItMultipartForm) {
    return { form, isItMultipartForm };
  }

  const formData = new FormData();
  files!.map(file => formData.append('files', file));
  if (form) {
    formData.append('form', form);
  }
  return { form: formData, isItMultipartForm };
};

interface IRequestParams {
  path: string;
  scopes: string[];
  method: string;
  data?: any;
  files?: File[];
  raiseErrorOnFailure: boolean;
  readData: (response: Response) => any;
}

const readJsonData = async (response: Response) => {
  const json = await response.text();
  return json ? JSON.parse(json) : undefined;
};

const readBinaryData = async (response: Response) => await response.blob();

const sendRequest = async <TResult>(params: IRequestParams, accessToken?:string): Promise<IApiResponse<TResult>> => {
  try {
      if (accessToken) {
          logInfo('Access Token available from API - ' + accessToken);
          return sendRequestWithAccessToken<TResult>(accessToken, params);
      }
      else {
        logInfo('Getting Access Token from API for scope - ' + params.scopes);
          return auth.getAccessToken(params.scopes).then(async token => {
            logInfo('Generated Access Token from API is - ' + token);
              return sendRequestWithAccessToken<TResult>(token, params);
          });
      }
  } catch (e) {
    logError(e);
    if (params.raiseErrorOnFailure) {
      reportUserError(GenericErrorMessage, params.path);
    }
    return { success: false };
  }
};

const sendRequestWithAccessToken = async <TResult>(accessToken: string, params: IRequestParams): Promise<IApiResponse<TResult>> => {
    const { form, isItMultipartForm } = formatData(params.data, params.files);
    const headers = makeHeaders(
        {
            authorization: `Bearer ${accessToken}`
        },
        isItMultipartForm
    );
    const response = await fetch(params.path, {
        body: form,
        method: params.method,
        cache: 'no-cache',
        headers
    });

    const obj = await params.readData(response);
    const responseOK = isResponseOk(response);

    return {
        success: responseOK,
        result: obj
    };
}

// Some sites like ServiceNow have their own polyfill for fetch which does
// not include the OK property, so use the status code instead as it's more
// reliable.
const isResponseOk = (response: Response) => {
  return response.status >= 200 && response.status <= 299;
};

const sendApiRequest = <TResult>(path: string, method: string, data?: any, files?: File[], accessToken?: string): Promise<IApiResponse<TResult>> =>
  sendRequest({
    path: makeUri(getConfig().apiBaseUrl, path),
    scopes: auth.getApplicationScopes(),
    method,
    data,
    files,
    raiseErrorOnFailure: true,
    readData: readJsonData
  },accessToken);

export const Api = {
    get: async <TResult>(path: string, accessToken?: string): Promise<IApiResponse<TResult>> => sendApiRequest<TResult>(path, 'GET',undefined,undefined,accessToken),
    post: async <TResult>(path: string, data?: any, files?: File[], accessToken?: string): Promise<IApiResponse<TResult>> => sendApiRequest<TResult>(path, 'POST', data, files,accessToken),
    put: async <TResult>(path: string, data?: any, files?: File[], accessToken?: string): Promise<IApiResponse<TResult>> => sendApiRequest<TResult>(path, 'PUT', data, files,accessToken),
    delete: async <TResult>(path: string, data?: any, accessToken?: string): Promise<IApiResponse<TResult>> => sendApiRequest<TResult>(path, 'DELETE', data,undefined,accessToken)
};

const graphApiUrlBase = 'https://graph.microsoft.com/v1.0';

export const GraphApi = {
  getUserPhoto: async (): Promise<any> => {
    return sendRequest({
      path: makeUri(graphApiUrlBase, 'me/photos/48x48/$value'),
      scopes: ['user.read'],
      method: 'GET',
      raiseErrorOnFailure: false,
      readData: readBinaryData
    });
  }
};
