import { DetailsList, IColumn, SelectionMode } from 'office-ui-fabric-react/lib-commonjs/DetailsList';
import { Image, ImageFit } from 'office-ui-fabric-react/lib-commonjs/Image';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Applications from '../../../shared/api/applications/applications';
import { ApplicationDto } from '../../../shared/api/models';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import { sortByColumn } from '../../../shared/util/details-list-util';

interface IAppSummary {
  id: string;
  name: string;
  categories: string;
  iconUrl: string;
  url: string;
  locationSpecific: string;
  hasMultipleUrls: boolean;
  isArchived: string;
}

interface IAdminAppListState {
  appSummaries: IAppSummary[];
  columns: IColumn[];
  workTracker: WorkTracker;
}

class AdminAppList extends Component<any, IAdminAppListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      appSummaries: [],
      columns: this.getColumns(),
      workTracker: new WorkTracker()
    };
  }

  public componentDidMount() {
    this.state.workTracker.track(this.loadApplications());
  }

  public render() {
    const { appSummaries: applications, columns } = this.state;
    return (
      <LoadingPanel workTracker={this.state.workTracker}>
        <DetailsList items={applications} columns={columns} compact={true} selectionMode={SelectionMode.none} />
      </LoadingPanel>
    );
  }

  private async loadApplications() {
    const response = await Applications.getMany(true);
    if (response.success) {
      const appSummaries = (response.result || []).map(
        (app: ApplicationDto): IAppSummary => {
          const hasMultipleUrls = app.urls.length > 1;
          return {
            id: app.id,
            name: app.name,
            categories: app.categories.map(c => c.name).join(', '),
            iconUrl: app.iconUrl,
            url: hasMultipleUrls ? '' : app.urls[0].url,
            hasMultipleUrls,
            locationSpecific: app.isLocationSpecific ? 'Yes' : 'No',
            isArchived: app.isArchived === true ? 'Yes' : 'No'
          };
        }
      );
      this.setState({ appSummaries });
      this.sort('name');
    }
  }

  private getColumns(): IColumn[] {
    return [
      {
        key: 'iconUrl',
        name: 'Icon',
        fieldName: 'iconUrl',
        minWidth: 40,
        maxWidth: 40,
        onColumnClick: this.onColumnClick,
        onRender: (app: ApplicationDto) => {
          return <Image src={app!.iconUrl} width={20} height={20} imageFit={ImageFit.cover} />;
        }
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 100,
        maxWidth: 500,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        isSortedDescending: true
      },
      {
        key: 'categories',
        name: 'Categories',
        fieldName: 'categories',
        minWidth: 100,
        maxWidth: 500,
        isResizable: true,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'locationSpecific',
        name: 'Location Specific?',
        fieldName: 'locationSpecific',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'url',
        name: 'URL',
        fieldName: 'url',
        minWidth: 300,
        maxWidth: 800,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: (app: IAppSummary) => {
          return app.hasMultipleUrls ? <span>Multiple URLs</span> : <a href={app.url}>{app.url}</a>;
        }
      },
      {
        key: 'isArchived',
        name: 'Archived?',
        fieldName: 'isArchived',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this.onColumnClick
      },
      {
        key: 'actions',
        name: 'Actions',
        fieldName: 'url',
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
        onRender: (app: IAppSummary) => {
          return <Link to={`/admin/applications/${app.id}`}>Edit</Link>;
        }
      }
    ];
  }

  private onColumnClick = (_: React.MouseEvent<HTMLElement>, column: IColumn) => {
    this.sort(column.key);
  };

  private sort(columnKey: string) {
    const sortResult = sortByColumn(this.state.appSummaries, this.state.columns, columnKey);
    this.setState({ appSummaries: sortResult.rows, columns: sortResult.columns });
  }
}

export default AdminAppList;
