import React, { Component } from 'react';
import SearchAllSites from '../SearchAllSites/SearchAllSites';
import HelpButton from '../HelpButton/HelpButton';
import WidgetApp from '../../../widget/routes/WidgetApp/WidgetApp';
import ProfileInfo from '../../../shared/components/ProfileInfo/ProfileInfo';
import { IconButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import './TopBar.scss';
import { EventBus, EventNames } from '../../../shared/eventbus/eventbus';

interface ITopBarState {
  areOptionsExpanded: boolean;
  isSearchExpanded: boolean;
}

const TopBarHeader = (props: { className?: string }) => (
  <header className={props.className}>
    <Link href="https://mybcecatholicedu.sharepoint.com">Brisbane Catholic Education</Link>
  </header>
);

export default class TopBar extends Component<any, ITopBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      areOptionsExpanded: false,
      isSearchExpanded: false
    };
  }

  public componentDidMount = () => {
    EventBus.subscribe(EventNames.MakeSureGlobalSearchIsVisible, this.showGlobalSearch);
    EventBus.subscribe(EventNames.MakeSureHelpIsVisible, this.showMoreOptions);
  };

  public componentWillUnmount = () => {
    EventBus.unsubscribe(EventNames.MakeSureGlobalSearchIsVisible, this.showGlobalSearch);
    EventBus.unsubscribe(EventNames.MakeSureHelpIsVisible, this.showMoreOptions);
  };

  public render = () => (
    <div className="TopBar">
      <div className="Full ms-slideLeftIn20">
        <TopBarHeader />
        <SearchAllSites className="LargeScreen" />
        <HelpButton className="LargeScreen" />
              <WidgetApp ensureAuthenticated={false} linkOnlyMode={false} className="LargeScreen" loginHint=""/>
        <ProfileInfo />
      </div>
      <div className="Medium ms-slideLeftIn20">
        <TopBarHeader />
        <IconButton iconProps={{ iconName: 'Search' }} onClick={this.toggleSearch} className="SearchButton" />
        <HelpButton className="MediumScreen" />
              <WidgetApp ensureAuthenticated={false} linkOnlyMode={false} className="MediumScreen" loginHint=""/>
        <ProfileInfo />
      </div>
      <div className="Small ms-slideLeftIn20">
        {this.state.areOptionsExpanded ? (
          <>
            <IconButton iconProps={{ iconName: 'Clear' }} onClick={this.hideOptions} className="CloseButton ms-slideLeftIn20" />
            <IconButton iconProps={{ iconName: 'Search' }} onClick={this.toggleSearch} className="SearchButton ms-slideLeftIn20" />
            <HelpButton className="ms-slideLeftIn20 SmallScreen" />
            <WidgetApp ensureAuthenticated={false} linkOnlyMode={false} className="ms-slideLeftIn20 SmallScreen" loginHint="" />
          </>
        ) : (
          <>
            <TopBarHeader className="ms-slideLeftIn20" />
            <IconButton iconProps={{ iconName: 'More' }} onClick={this.showMoreOptions} className="MoreButton ms-slideLeftIn20" />
          </>
        )}
        <ProfileInfo />
      </div>
      {this.state.isSearchExpanded && (
        <div className="SearchContainer ms-slideDownIn20">
          <SearchAllSites className="SmallScreen" />
        </div>
      )}
    </div>
  );

  private showMoreOptions = () => this.setState({ areOptionsExpanded: true });

  private hideOptions = () => this.setState({ areOptionsExpanded: false, isSearchExpanded: false });

  private toggleSearch = () => this.setState({ isSearchExpanded: !this.state.isSearchExpanded });

  private showGlobalSearch = () => this.setState({ areOptionsExpanded: true, isSearchExpanded: true });
}
