import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { IFeatureToShow } from '../IFeatureToShow';

export const ByCategoryTabFeature: IFeatureToShow = {
  getSelector: () => 'button[data-tab-button-id="ByCategoryButton"]',
  directionalHint: DirectionalHint.rightCenter,
  title: 'By Category',
  description: `You can view apps by category or as an alphabetically ordered list.`,
  featureDidShowUp: () => {
    const element = document.querySelector('button[data-tab-button-id="ByCategoryButton"]');
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};
