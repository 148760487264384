import { orderBy } from 'lodash';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { DetailsList, IColumn, SelectionMode } from 'office-ui-fabric-react/lib-commonjs/DetailsList';
import { Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react/lib-commonjs/Dialog';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Categories from '../../../shared/api/categories/categories';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';

interface ICategorySummary {
  id: string;
  name: string;
}

interface IAdminCategoryListState {
  categorySummaries: ICategorySummary[];
  columns: IColumn[];
  isDeleteDialogHidden: boolean;
  categoryToDelete?: ICategorySummary;
  workTracker: WorkTracker;
}

class AdminCategoryList extends Component<any, IAdminCategoryListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      categorySummaries: [],
      columns: this.getColumns(),
      isDeleteDialogHidden: true,
      workTracker: new WorkTracker()
    };
  }

  public componentDidMount() {
    this.state.workTracker.track(this.loadCategories());
  }

  public render() {
    const { categorySummaries, columns } = this.state;
    return (
      <LoadingPanel workTracker={this.state.workTracker}>
        <Dialog
          hidden={this.state.isDeleteDialogHidden}
          onDismiss={this.closeDeleteDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Delete Category',
            subText: `Are you sure you want to delete this category "${this.state.categoryToDelete ? this.state.categoryToDelete.name : ''}"?`
          }}
          modalProps={{
            titleAriaId: 'myLabelId',
            subtitleAriaId: 'mySubTextId',
            isBlocking: true,
            containerClassName: 'ms-dialogMainOverride'
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.deleteCategory} text="Delete" />
            <DefaultButton onClick={this.closeDeleteDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
        <DetailsList items={categorySummaries} columns={columns} compact={true} selectionMode={SelectionMode.none} />
      </LoadingPanel>
    );
  }

  private openDeleteDialog = (categoryToDelete: ICategorySummary) => {
    this.setState({ isDeleteDialogHidden: false, categoryToDelete });
  };

  private closeDeleteDialog = () => {
    this.setState({ isDeleteDialogHidden: true, categoryToDelete: undefined });
  };

  private deleteCategory = async () => {
    const result = await Categories.remove(this.state.categoryToDelete!.id);
    if (result.success) {
      const categorySummaries = this.state.categorySummaries.slice(0);
      const indexToDelete = categorySummaries.indexOf(this.state.categoryToDelete!);
      categorySummaries.splice(indexToDelete, 1);
      this.setState({ categorySummaries });
      toast.success('Category was successfully deleted');
    }

    this.closeDeleteDialog();
  };

  private async loadCategories() {
    const response = await Categories.getAll();
    if (response.success) {
      const categorySummaries = (response.result || []).map(category => ({
        id: category.id,
        name: category.name
      }));
      this.setState({ categorySummaries });
      this.sortByColumn('name');
    }
  }

  private getColumns(): IColumn[] {
    return [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 100,
        maxWidth: 500,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        isSortedDescending: true
      },
      {
        key: 'actions',
        name: 'Actions',
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
        onRender: (category: ICategorySummary) => {
          return (
            <>
              <Link to={`/admin/categories/${category.id}`}>Edit</Link>
              <Link to="#" style={{ marginLeft: '1rem' }} onClick={this.getDeleteCallback(category)}>
                Delete
              </Link>
            </>
          );
        }
      }
    ];
  }

  private getDeleteCallback = (category: ICategorySummary) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.openDeleteDialog(category);
  };

  private onColumnClick = (_: React.MouseEvent<HTMLElement>, column: IColumn) => {
    this.sortByColumn(column.key);
  };

  private sortByColumn(sortColumnKey: string) {
    const { columns } = this.state;
    let isSortedDescending = false;
    for (const column of columns) {
      if (column.key === sortColumnKey) {
        column.isSorted = true;
        column.isSortedDescending = !column.isSortedDescending;
        isSortedDescending = column.isSortedDescending;
      } else {
        column.isSorted = false;
        column.isSortedDescending = true;
      }
    }

    const categorySummaries = this.copyAndSort(this.state.categorySummaries, sortColumnKey, isSortedDescending);

    this.setState({ categorySummaries, columns });
  }

  private copyAndSort(categories: ICategorySummary[], field: string, isSortedDescending: boolean): ICategorySummary[] {
    const key = field as keyof ICategorySummary;
    return orderBy(
      categories.slice(0),
      [category => (category[key] ? category[key].toUpperCase() : category[key])],
      [isSortedDescending ? 'desc' : 'asc']
    );
  }
}

export default AdminCategoryList;
