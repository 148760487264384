import { Image } from 'office-ui-fabric-react/lib-commonjs/Image';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import React, { Component } from 'react';
import { Icon } from 'office-ui-fabric-react/lib-commonjs/Icon';
import { IStyle } from '@uifabric/styling';
import { ActionButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { getConfig } from '../../../shared/config/config';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import './AppNavList.scss';
import { reportAppUsage } from '../../../shared/analytics/analytics';

interface IAppNavListProps {
  favoriteApps: ILocationApplication[];
  enterEditMode: () => void;
  userProfiles: string[];
  mainAppUrl: string | undefined;
}

const MoreAppLinks = (props: { mainAppUrl: string | undefined }) => (
  <ActionButton href={props.mainAppUrl} target="_blank" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
    More BCE Applications
    <Icon iconName="Forward" className="ms-IconForward" style={{ marginLeft: '8px', color: 'rgb(16, 110, 190)' }} />
  </ActionButton>
);

const ApplicationIconStyles: IStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: '0.5rem',
  padding: '0.2rem 0'
};

class AppNavList extends Component<IAppNavListProps, any> {
  constructor(props: IAppNavListProps) {
    super(props);
  }

  public render = () => (
    <div>
      <div className="PrimaryTitle">BCE Apps</div>
      <div className="ApplicationList">
        {this.props.favoriteApps && this.props.favoriteApps.map((a: ILocationApplication) => this.renderApplication(a))}
      </div>
      <MoreAppLinks mainAppUrl={this.props.mainAppUrl} />
      <ActionButton
        id="SideDrawEditButton"
        className="EditMenu"
        onClick={this.props.enterEditMode}
        iconProps={{ iconName: 'Settings' }}
        style={{ marginTop: '1.5rem' }}
      >
        Edit Menu
      </ActionButton>
    </div>
  );

  private readonly renderApplication = (app: ILocationApplication) => (
    <div className="AppNavItem" key={app.id}>
      <Link
        href={app!.url}
        onClick={this.onAppClick(app)}
        title={app!.appName}
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <Image src={app!.iconUrl} width={32} height={32} styles={{ root: ApplicationIconStyles }} />
        <span className="AppName">{app!.appName}</span>
      </Link>
    </div>
  );

  private readonly onAppClick = (app: ILocationApplication) => async () => {
    await reportAppUsage(app, this.props.userProfiles, 'SideDraw');
    return true;
  };
}

export default AppNavList;
