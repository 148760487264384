import React from 'react';
import RouteLink, { RouteLinkType } from '../CustomRoutes/RouteLink';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib-commonjs/Spinner';

const SaveButton = (props: { isFormValid: boolean; isSubmitting: boolean; submitForm: () => Promise<boolean> }) => (
  <RouteLink to="/admin" action={props.submitForm} linkType={RouteLinkType.PrimaryButton} disabled={!props.isFormValid || props.isSubmitting}>
    {props.isSubmitting && <Spinner size={SpinnerSize.small} />}
    Save
  </RouteLink>
);

export default SaveButton;
