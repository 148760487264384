import { Component, default as React } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Application } from './Application';
import './AppsByCategory.scss';
import { CategoryWithApps } from './CategoryWithApps';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import { IconButton, ActionButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { IApplicationOperations } from './Apps';

interface ICategoryProps {
  applicationOperations: IApplicationOperations;
  categoryWithApps: CategoryWithApps;
  isCollapsingAllowed: boolean;
  onToggleCategory: () => void;
  userProfiles: string[];
}

const Category = (props: ICategoryProps) => (
  <div key={props.categoryWithApps.id} className="ApplicationGroup ms-slideDownIn10">
    <h1 className={props.isCollapsingAllowed && props.categoryWithApps.isCollapsed ? 'Collapsed' : undefined}>
      {props.isCollapsingAllowed && (
        <IconButton
          iconProps={{ iconName: 'ChevronUp' }}
          onClick={props.onToggleCategory}
          className={props.categoryWithApps.isCollapsed ? 'Collapsed' : undefined}
        />
      )}
      {props.categoryWithApps.name}
    </h1>
    {!(props.isCollapsingAllowed && props.categoryWithApps.isCollapsed) && (
      <TransitionGroup enter={false} exit={true}>
        {props.categoryWithApps.applications.map(a => (
          <CSSTransition key={a.id} classNames={{ exit: 'ms-slideUpOut10' }} timeout={100}>
            <Application userProfiles={props.userProfiles} application={a} applicationOperations={props.applicationOperations} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    )}
  </div>
);

interface IApplicationCategoryColumnProps {
  applicationOperations: IApplicationOperations;
  categoriesWithApps: CategoryWithApps[];
  isCollapsingAllowed: boolean;
  onToggleCategory: (category: CategoryWithApps) => void;
  userProfiles: string[];
}

const ApplicationCategoryColumn = (props: IApplicationCategoryColumnProps) => {
  const toggleCategory = (category: CategoryWithApps) => () => {
    props.onToggleCategory(category);
  };
  return (
    <div className="ms-slideDownIn10 Column">
      {props.categoriesWithApps.map(c => (
        <Category
          key={c.id}
          categoryWithApps={c}
          isCollapsingAllowed={props.isCollapsingAllowed}
          onToggleCategory={toggleCategory(c)}
          applicationOperations={props.applicationOperations}
          userProfiles={props.userProfiles}
        />
      ))}
    </div>
  );
};

interface IAppsByCategoryProps {
  applicationOperations: IApplicationOperations;
  applications: ILocationApplication[] | null;
  onToggleCategory: (categoryId: string) => void;
  onOpenAllCategories: () => void;
  onCloseAllCategories: () => void;
  userProfiles: string[];
}

class AppsByCategory extends Component<IAppsByCategoryProps, {}> {
  constructor(props: IAppsByCategoryProps) {
    super(props);
  }

  public render = () => {
    const categoriesWithApps = this.getCategoriesWithApps();
    const threeColumn = this.getThreeColumnConfiguration(categoriesWithApps);
    return (
      <div className="ByCategory">
        <div className="OneColumnList">
          <div className="CategoryToggles">
            <ActionButton onClick={this.openAllCategories}>Open All</ActionButton>
            <ActionButton onClick={this.closeAllCategories}>Close All</ActionButton>
          </div>
          <ApplicationCategoryColumn
            isCollapsingAllowed={true}
            categoriesWithApps={categoriesWithApps}
            onToggleCategory={this.toggleCategory}
            applicationOperations={this.props.applicationOperations}
            userProfiles={this.props.userProfiles}
          />
        </div>
        <div className="ThreeColumnList">
          {threeColumn.map((col, i) => (
            <ApplicationCategoryColumn
              isCollapsingAllowed={false}
              key={i}
              categoriesWithApps={col}
              onToggleCategory={this.toggleCategory}
              applicationOperations={this.props.applicationOperations}
              userProfiles={this.props.userProfiles}
            />
          ))}
        </div>
      </div>
    );
  };

  private openAllCategories = () => {
    this.props.onOpenAllCategories();
  };

  private closeAllCategories = () => {
    this.props.onCloseAllCategories();
  };

  private toggleCategory = (categoryWithApps: CategoryWithApps) => {
    this.props.onToggleCategory(categoryWithApps.id);
  };

  private readonly getCategoriesWithApps = (): CategoryWithApps[] => {
    if (this.props.applications) {
      const categories: { [id: string]: CategoryWithApps } = {};
      this.props.applications.map(a =>
        a.categories.map(c => (categories[c.id] ? categories[c.id].appendApp(a) : (categories[c.id] = new CategoryWithApps(c)).appendApp(a)))
      );
      return Object.keys(categories)
        .map(id => categories[id])
        .sort((c1, c2) => c1.name.toUpperCase().localeCompare(c2.name.toUpperCase()));
    }
    return [];
  };

  private getThreeColumnConfiguration = (categories: CategoryWithApps[]): CategoryWithApps[][] => {
    const columnPermutations: number[][] = [];

    for (let col2Start = 1; col2Start < categories.length; col2Start++) {
      for (let col3Start = col2Start + 1; col3Start < categories.length; col3Start++) {
        columnPermutations.push([0, col2Start, col3Start]);
      }
    }

    return this.getBestColumnConfiguration(categories, columnPermutations);
  };

  private getBestColumnConfiguration = (categories: CategoryWithApps[], columnPermutations: number[][]): CategoryWithApps[][] => {
    const totalAppCount = this.getColumnSize([...categories]);
    const idealAppCount = totalAppCount / (columnPermutations[0] ? columnPermutations[0].length : 1);

    let bestConfig: IColumnConfiguration = {
      maxDeviation: 99999,
      columns: [categories]
    };

    for (const permutation of columnPermutations) {
      const columnConfig = this.getColumnConfiguration(categories, permutation, idealAppCount);
      if (columnConfig.maxDeviation < bestConfig.maxDeviation) {
        bestConfig = columnConfig;
      }
    }

    return bestConfig.columns;
  };

  private getColumnConfiguration(categories: CategoryWithApps[], colStarts: number[], idealLength: number): IColumnConfiguration {
    const columns = colStarts.map((start, i) => {
      const end = i < colStarts.length - 1 ? colStarts[i + 1] : categories.length;
      return categories.slice(start, end);
    });

    const maxDeviation = Math.max(...columns.map(col => this.getColumnSize(col) - idealLength));

    return {
      maxDeviation,
      columns
    };
  }

  private getColumnSize(column: CategoryWithApps[]): number {
    // headers take up as much space as 2 apps
    return column.map(c => c.appCount).reduce((a, b) => a + b, 0) + column.length * 2;
  }
}

interface IColumnConfiguration {
  maxDeviation: number;
  columns: CategoryWithApps[][];
}

export default AppsByCategory;
