import React, { Component } from 'react';
import { auth } from '../../../shared/auth/auth';
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib-commonjs/Persona';
import { GraphApi } from '../../../shared/api/api';

interface IProfileInfoState {
  photoUrl: string;
}

export default class ProfileInfo extends Component<any, IProfileInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      photoUrl: ''
    };
  }

  public componentDidMount = async () => {
    this.loadUserProfilePhoto();
  };

  public render = () => (
    <Persona
      imageUrl={this.state.photoUrl}
      size={PersonaSize.size28}
      presence={PersonaPresence.online}
      hidePersonaDetails={false}
      text={auth.getUserProfile()!.displayName}
    />
  );

  private loadUserProfilePhoto = async () => {
    const photoResponse = await GraphApi.getUserPhoto();
    if (photoResponse.success) {
      const url = window.URL || (window as any).webkitURL;
      const photoUrl = url.createObjectURL(photoResponse.result);
      this.setState({ photoUrl });
    }
  };
}
