import { Label } from 'office-ui-fabric-react/lib-commonjs/Label';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib-commonjs/MessageBar';
import { TextField } from 'office-ui-fabric-react/lib-commonjs/TextField';
import React, { Component } from 'react';
import { CategoryDto, CategoryEntryDto } from '../../../shared/api/models';
import * as Categories from '../../../shared/api/categories/categories';
import RouteLink, { RouteLinkType } from '../CustomRoutes/RouteLink';
import SaveButton from '../SaveButton/SaveButton';
import { toast } from 'react-toastify';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';

interface IAddEditCategoryState {
  isNew: boolean;
  isSubmitting: boolean;
  availableCategories: CategoryDto[];
  form: CategoryEntryDto;
  workTracker: WorkTracker;
}

class CategoryForm extends Component<any, IAddEditCategoryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isNew: props.match.params.id === 'new',
      isSubmitting: false,
      availableCategories: [],
      form: {
        name: ''
      },
      workTracker: new WorkTracker()
    };
  }

  public render() {
    return (
      <LoadingPanel workTracker={this.state.workTracker}>
        <form className="Form">
          <div className="FormRow">
            <Label required={true} htmlFor="CategoryName">
              Name
            </Label>
            <TextField id="CategoryName" value={this.state.form.name} onChange={this.onAppNameChanged} autoComplete="off" autoFocus={true} maxLength={100} />
          </div>

          {this.isCategoryNameTaken() && (
            <div className="FormRow">
              <MessageBar messageBarType={MessageBarType.blocked}>Sorry, this category name is already taken. Please choose another name.</MessageBar>
            </div>
          )}

          <div className="FormFooter">
            <SaveButton isSubmitting={this.state.isSubmitting} isFormValid={this.isFormValid()} submitForm={this.submitForm} />
            <RouteLink to="/admin" linkType={RouteLinkType.DefaultButton} style={{ marginLeft: '0.5rem' }}>
              Cancel
            </RouteLink>
          </div>
        </form>
      </LoadingPanel>
    );
  }

  public componentDidMount() {
    this.state.workTracker.track(this.loadCategories());
  }

  private async loadCategories() {
    const response = await Categories.getAll();
    if (response.success) {
      this.setState({ availableCategories: response.result! });
      if (!this.state.isNew) {
        const category = this.state.availableCategories.find(c => c.id === this.props.match.params.id);
        if (!category) {
          toast.error('Sorry, the selected category could not be loaded');
        } else {
          this.setState({ form: { ...this.state.form, name: category!.name } });
        }
      }
    }
  }

  private readonly submitForm = async (): Promise<boolean> => {
    this.setState({ isSubmitting: true });

    const response = this.state.isNew ? await Categories.add(this.state.form) : await Categories.update(this.props.match.params.id, this.state.form);

    this.setState({ isSubmitting: false });
    if (response.success) {
      toast.success('Category was successfully saved');
    }

    return response.success;
  };

  private readonly isFormValid = () => !!this.state.form.name && !this.isCategoryNameTaken();

  private readonly isCategoryNameTaken = () =>
    this.state.availableCategories.some(
      c => c.name.toLowerCase() === this.state.form.name.trim().toLowerCase() && c.id !== this.props.match.params.id
    );

  private readonly onAppNameChanged = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, name?: string) => {
    this.setState({ form: { ...this.state.form, name: name || '' } });
  };
}

export default CategoryForm;
