import React, { Component } from 'react';
import { TagPicker, ITag } from 'office-ui-fabric-react/lib-commonjs/Pickers';
import * as Tags from '../../../shared/api/tags/tags';

interface IApplicationTagPickerProps {
  id?: string;
  tags: string[] | undefined;
  onChange?: (tags: string[]) => void;
}

interface IApplicationTagPickerState {
  availableTags: ITag[];
}

export class ApplicationTagPicker extends Component<IApplicationTagPickerProps, IApplicationTagPickerState> {
  constructor(props: IApplicationTagPickerProps) {
    super(props);

    this.state = {
      availableTags: []
    };
  }

  public componentDidMount() {
    this.loadTags();
  }

  public render = () => (
    <TagPicker
      selectedItems={(this.props.tags || []).map(t => ({ key: t, name: t }))}
      onResolveSuggestions={this.onResolveSuggestions}
      getTextFromItem={this.onGetTextFromItem}
      onChange={this.onTagListChange}
      pickerSuggestionsProps={{
        suggestionsHeaderText: 'Suggested Tags',
        noResultsFoundText: 'No Tags Found'
      }}
      inputProps={{
        'aria-label': 'Tag Picker',
        id: this.props.id
      }}
    />
  );

  private async loadTags() {
    const response = await Tags.getAll();
    if (response.success && response.result) {
      this.setState({
        availableTags: response.result.sort((t1, t2) => t1.toLowerCase().localeCompare(t2.toLowerCase())).map(item => ({ key: item, name: item }))
      });
    }
  }
  private onGetTextFromItem = (item: ITag) => item.name;

  private onResolveSuggestions = (filterText: string, existingTags?: ITag[]): ITag[] =>
    getTagSuggestions(filterText, existingTags || [], this.state.availableTags);

  private onTagListChange = (items?: ITag[] | undefined) => {
    const tagList = items ? items!.map(t => t.name) : [];
    if (this.props.onChange) {
      this.props.onChange(tagList);
    }
  };
}

export const getTagSuggestions = (filterText: string, existingTags: ITag[], availableTags: ITag[]): ITag[] => {
  const filterTextTrimmed = (filterText || '').trim();
  if (!filterTextTrimmed) {
    return [];
  }
  const filteredTags = availableTags.filter(tag => tag.name.toLowerCase().indexOf(filterTextTrimmed.toLowerCase()) >= 0);
  const withTheCurrentItemOnTop =
    filteredTags.map(t => t.name).indexOf(filterTextTrimmed.toLowerCase()) >= 0
      ? filteredTags
      : [{ key: filterTextTrimmed, name: filterTextTrimmed }].concat(filteredTags);
  const andRemovedDuplicates = withTheCurrentItemOnTop.filter(tag => !isTagAlreadyOnTheList(tag, existingTags));
  return andRemovedDuplicates;
};

const isTagAlreadyOnTheList = (tag: ITag, existingTags: ITag[]) =>
  existingTags.some(compareTag => compareTag.key.toLowerCase() === tag.key.toLowerCase());
