import * as React from 'react';
import './LoadingPanel.scss';
import { WorkTracker } from './work-tracker';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib-commonjs/Spinner';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

interface ILoadingPanelProps {
  workTracker: WorkTracker;
  style?: React.CSSProperties;
}

interface ILoadingPanelState {
  isLoadingComplete: boolean;
}

class LoadingPanel extends React.Component<ILoadingPanelProps, ILoadingPanelState> {
  constructor(props: ILoadingPanelProps) {
    super(props);
    this.state = {
      isLoadingComplete: this.props.workTracker.isComplete
    };
    this.updateIsLoadingComplete = this.updateIsLoadingComplete.bind(this);
  }

  public componentDidMount() {
    this.props.workTracker.subscribe(this.updateIsLoadingComplete);
  }

  public componentWillUnmount() {
    this.props.workTracker.unsubscribe(this.updateIsLoadingComplete);
  }

  public render() {
    const loaded = this.state.isLoadingComplete;
    return (
      <div className={`LoadingPanel ${loaded ? 'Loaded' : 'Loading'}`} style={this.props.style}>
        <TransitionGroup enter={true} exit={true} appear={true}>
          {loaded ? null : (
            <CSSTransition classNames="Loading" timeout={400}>
              <div>
                <Spinner className="Spinner" size={SpinnerSize.large} />
                <div className="Overlay" />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        <div className="Content">{this.props.children}</div>
      </div>
    );
  }

  private updateIsLoadingComplete(isComplete: boolean) {
    this.setState({ isLoadingComplete: isComplete });
  }
}

export default LoadingPanel;
