import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { MaximumLargeScreenWidth, IFeatureToShow, IFeatureHost } from '../IFeatureToShow';
import { AppTabKeys } from '../../../routes/MainApp/MainApp';

const getElement = (host: IFeatureHost) => {
  const elementSelector =
    host.activeTab === AppTabKeys.Category
      ? window.matchMedia(`(max-width: ${MaximumLargeScreenWidth}px)`).matches
        ? `.ByCategory .OneColumnList .AppDescriptionIcon`
        : `.ByCategory .ThreeColumnList .AppDescriptionIcon`
      : '.AZList .Column .AppDescriptionIcon';
  return document.querySelector<HTMLElement>(elementSelector);
};

export const AppDescriptionIconFeature: IFeatureToShow = {
  getSelector: getElement,
  directionalHint: DirectionalHint.topCenter,
  title: 'Description',
  description: `Click on the information icon to view a short description about each app. If you would like to know more, follow the "more information" link at the end of the description to be taken to the Spire page about the app (where available).`,
  featureDidShowUp: (host: IFeatureHost) => {
    const element = getElement(host);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};
