import { orderBy } from 'lodash';
import { Checkbox } from 'office-ui-fabric-react/lib-commonjs/Checkbox';
import { Image } from 'office-ui-fabric-react/lib-commonjs/Image';
import { Label } from 'office-ui-fabric-react/lib-commonjs/Label';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib-commonjs/MessageBar';
import { Pivot, PivotItem, PivotLinkFormat } from 'office-ui-fabric-react/lib-commonjs/Pivot';
import { TextField } from 'office-ui-fabric-react/lib-commonjs/TextField';
import React, { ChangeEvent, Component } from 'react';
import { toast } from 'react-toastify';
import * as Applications from '../../../shared/api/applications/applications';
import * as Categories from '../../../shared/api/categories/categories';
import * as Locations from '../../../shared/api/locations/locations';
import * as Profiles from '../../../shared/api/profiles/profiles';
import { ApplicationEntryDto, CategoryDto, UserLocation, ApplicationUrlEntryDto, ProfileDto } from '../../../shared/api/models';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import ApplicationUrls, { IDetailedApplicationUrl } from '../ApplicationUrls/ApplicationUrls';
import RouteLink, { RouteLinkType } from '../CustomRoutes/RouteLink';
import SaveButton from '../SaveButton/SaveButton';
import { ApplicationTagPicker } from './ApplicationTagPicker';

interface IApplicationFormState {
  isNew: boolean;
  isSubmitting: boolean;
  availableCategories: CategoryDto[];
  availableProfiles: ProfileDto[];
  availableLocations: UserLocation[];
  isLocationSpecific: boolean;
  locationSpecificUrls: IDetailedApplicationUrl[];
  globalUrl: IDetailedApplicationUrl;
  currentIconUrl: string | undefined;
  form: ApplicationEntryDto;
  iconFile: File | undefined;
  workTracker: WorkTracker;
}

class ApplicationForm extends Component<any, IApplicationFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isNew: props.match.params.id === 'new',
      isSubmitting: false,
      availableCategories: [],
      availableProfiles: [],
      availableLocations: [],
      isLocationSpecific: false,
      locationSpecificUrls: [],
      globalUrl: { isActive: true, location: undefined, url: '' },
      currentIconUrl: undefined,
      iconFile: undefined,
      form: {
        name: '',
        description: '',
        moreInformationUrl: '',
        urls: [],
        categoryIds: [],
        profileCodes: [],
        tags: [],
        isArchived: false
      },
      workTracker: new WorkTracker()
    };
  }

  public render() {
    return (
      <LoadingPanel workTracker={this.state.workTracker}>
        <form className="Form">
          <h2>{`Application${this.state.form.name ? ' - ' + this.state.form.name : ''}`}</h2>
          <Pivot linkFormat={PivotLinkFormat.links} className="ApplicationForm">
            <PivotItem linkText="Basic Details">
              <div className="FormContent">
                <div className="FormRow">
                  <Label required={true} htmlFor="ApplicationName">
                    Name
                  </Label>
                  <TextField
                    id="ApplicationName"
                    value={this.state.form.name}
                    onChange={this.onAppNameChanged}
                    autoComplete="off"
                    autoFocus={true}
                    maxLength={100}
                  />
                </div>

                <div className="FormRow">
                  <Label required={true} htmlFor="ApplicationDescription">
                    Description
                  </Label>
                  <TextField
                    id="ApplicationDescription"
                    value={this.state.form.description}
                    onChange={this.onAppDescriptionChanged}
                    autoComplete="off"
                    maxLength={150}
                  />
                </div>

                <div className="FormRow">
                  <Label required={false} htmlFor="ApplicationMoreInformationUrl">
                    More Information URL
                  </Label>
                  <TextField
                    id="ApplicationMoreInformationUrl"
                    value={this.state.form.moreInformationUrl}
                    onChange={this.onAppMoreInfoUrlChanged}
                    autoComplete="off"
                    maxLength={2000}
                  />
                </div>

                <div className="FormRow">
                  <Label htmlFor="ApplicationTags">Tags</Label>
                  <ApplicationTagPicker id="ApplicationTags" tags={this.state.form.tags} onChange={this.listOfTagsChanged} />
                </div>

                <div className="FormRow">
                  <Label htmlFor="ApplicationIconUrl">{this.state.isNew ? null : 'New '}Icon</Label>
                  <input id="ApplicationIconUrl" type="file" name="icon" onChange={this.onIconFileSelected} accept="image/*" />
                </div>

                <div className="FormRow">
                  <MessageBar messageBarType={MessageBarType.warning}>
                    Please make sure the selected image is fairly small (preferably up to 64x64 pixels) and square.
                  </MessageBar>
                </div>

                <CurrentIcon isNew={this.state.isNew} url={this.state.currentIconUrl} />

                <Archive isNew={this.state.isNew} isArchived={this.state.form.isArchived} onChange={this.onIsArchivedChanged} />
              </div>
            </PivotItem>
            <PivotItem linkText="Categories">
              <div className="FormContent">
                <div className="FormRow">
                  <div>
                    {this.state.availableCategories.map(c => (
                      <Checkbox
                        key={c.id}
                        label={c.name}
                        checked={this.state.form.categoryIds.find((id: string) => id === c.id) !== undefined}
                        onChange={this.onCategorySelectionChanged(c.id)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </PivotItem>
            <PivotItem linkText="Allowed Profiles">
              <div className="FormContent">
                <div className="FormRow">
                  <div>
                    {this.state.availableProfiles.map(profile => (
                      <Checkbox
                        key={profile.profileCode}
                        label={profile.displayName}
                        checked={this.state.form.profileCodes.find((id: string) => id === profile.profileCode) !== undefined}
                        onChange={this.onProfileSelectionChanged(profile.profileCode)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </PivotItem>
            <PivotItem linkText="URLs">
              <div className="FormFooter">
                <SaveButton isSubmitting={this.state.isSubmitting} isFormValid={this.isFormValid()} submitForm={this.submitForm} />
                <RouteLink to="/admin" linkType={RouteLinkType.DefaultButton} style={{ marginLeft: '0.5rem' }}>
                  Cancel
                </RouteLink>
              </div>
              <ApplicationUrls
                locationSpecificUrls={this.state.locationSpecificUrls}
                globalUrl={this.state.globalUrl}
                isLocationSpecific={this.state.isLocationSpecific}
                onIsLocationSpecificChange={this.onIsLocationSpecificChanged}
                onGlobalUrlChange={this.onGlobalUrlChanged}
                onLocationSpecificUrlChange={this.onLocationSpecificUrlsChanged}
              />
            </PivotItem>
          </Pivot>
          <div className="FormFooter">
            <SaveButton isSubmitting={this.state.isSubmitting} isFormValid={this.isFormValid()} submitForm={this.submitForm} />
            <RouteLink to="/admin" linkType={RouteLinkType.DefaultButton} style={{ marginLeft: '0.5rem' }}>
              Cancel
            </RouteLink>
          </div>
        </form>
      </LoadingPanel>
    );
  }

  public async componentDidMount() {
    window.scrollTo(0, 0);
    this.state.workTracker.track(this.loadData());
  }

  private async loadData() {
    await Promise.all([this.loadCategories(), this.loadProfiles(), this.loadLocations()]);

    if (!this.state.isNew) {
      await this.loadApplication();
    } else {
      this.initNewApplication();
    }
  }

  private async loadCategories() {
    const response = await Categories.getAll();
    if (response.success) {
      this.setState({ availableCategories: orderBy(response.result, 'name', 'asc') });
    }
  }

  private async loadProfiles() {
    const response = await Profiles.getAll();
    if (response.success) {
      this.setState({ availableProfiles: orderBy(response.result, 'displayName', 'asc') });
      if (this.state.isNew) {
        this.setState({ form: { ...this.state.form, profileCodes: this.state.availableProfiles.map(p => p.profileCode) } });
      }
    }
  }

  private async loadLocations() {
    const response = await Locations.getAll();
    if (response.success && response.result) {
      this.setState({ availableLocations: response.result });
    }
  }

  private async loadApplication() {
    const response = await Applications.get(this.props.match.params.id);
    if (response.success && response.result) {
      const app = response.result;
      this.setState({
        isLocationSpecific: app.isLocationSpecific,
        locationSpecificUrls: this.state.availableLocations.map(l => {
          const match = app.urls.find(u => u.locationCode === l.locationCode);
          return (match ? { isActive: true, location: l, url: match.url } : { isActive: false, location: l }) as IDetailedApplicationUrl;
        }),
        globalUrl: app.urls ? { isActive: true, url: app.urls[0].url } : this.state.globalUrl,
        currentIconUrl: app.iconUrl,
        form: {
          name: app.name,
          description: app.description,
          moreInformationUrl: app.moreInformationUrl,
          urls: app.urls,
          categoryIds: app.categories.map(c => c.id),
          profileCodes: app.profileCodes,
          tags: app.tags,
          isArchived: app.isArchived
        }
      });
    }
  }

  private initNewApplication() {
    this.setState({ locationSpecificUrls: this.state.availableLocations.map(l => ({ isActive: false, location: l })) });
  }

  private readonly submitForm = async (): Promise<boolean> => {
    this.setState({ isSubmitting: true });

    const urlsToSave = this.state.isLocationSpecific
      ? this.state.locationSpecificUrls.filter(l => l.isActive && l.url && l.url.trim())
      : [this.state.globalUrl];
    const form: ApplicationEntryDto = {
      ...this.state.form,
      urls: urlsToSave.map((u): ApplicationUrlEntryDto => ({ locationCode: u.location ? u.location.locationCode : null, url: u.url || '' }))
    };

    const response = this.state.isNew
      ? await Applications.add(form, this.state.iconFile)
      : await Applications.update(this.props.match.params.id, form, this.state.iconFile);

    this.setState({ isSubmitting: false });
    if (response.success) {
      toast.success('Application was successfully saved');
    }

    return response.success;
  };

  private readonly isFormValid = (): boolean => {
    const hasValidUrl =
      (this.state.isLocationSpecific && this.state.locationSpecificUrls.every(l => !l.isActive || (l.isActive && !!l.url))) ||
      (!this.state.isLocationSpecific && !!this.state.globalUrl.url);

    return (
      !!this.state.form.name &&
      !!this.state.form.description &&
      hasValidUrl &&
      this.state.form.categoryIds.length > 0 &&
      this.state.form.profileCodes.length > 0
    );
  };

  private readonly onIconFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.setState({ iconFile: file });
    }
  };

  private readonly onCategorySelectionChanged = (categoryId: string) => (_?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) =>
    this.setState({
      form: {
        ...this.state.form,
        categoryIds: isChecked ? this.state.form.categoryIds.concat([categoryId]) : this.state.form.categoryIds.filter(id => id !== categoryId)
      }
    });

  private readonly onProfileSelectionChanged = (profileCode: string) => (_?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) =>
    this.setState({
      form: {
        ...this.state.form,
        profileCodes: isChecked ? this.state.form.profileCodes.concat([profileCode]) : this.state.form.profileCodes.filter(id => id !== profileCode)
      }
    });

  private readonly listOfTagsChanged = (tags: string[]) => {
    this.setState({ form: { ...this.state.form, tags } });
  };

  private readonly onAppNameChanged = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, name?: string) => {
    this.setState({ form: { ...this.state.form, name: name || '' } });
  };

  private readonly onAppDescriptionChanged = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, description?: string) => {
    this.setState({ form: { ...this.state.form, description: description || '' } });
  };

  private readonly onAppMoreInfoUrlChanged = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, moreInformationUrl?: string) => {
    this.setState({ form: { ...this.state.form, moreInformationUrl: moreInformationUrl || '' } });
  };

  private readonly onIsLocationSpecificChanged = (isLocationSpecific: boolean) => {
    this.setState({ isLocationSpecific, globalUrl: { ...this.state.globalUrl, url: '' } });
  };

  private readonly onGlobalUrlChanged = (url: IDetailedApplicationUrl) => {
    this.setState({ globalUrl: url });
  };

  private readonly onLocationSpecificUrlsChanged = (urls: IDetailedApplicationUrl[]) => {
    this.setState({ locationSpecificUrls: urls });
  };

  private readonly onIsArchivedChanged = (_?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    this.setState({ form: { ...this.state.form, isArchived: isChecked || false } });
  };
}

const CurrentIcon = (props: { isNew: boolean; url: string | undefined }) => {
  if (props.isNew) {
    return null;
  }

  return (
    <div className="FormRow">
      <Label htmlFor="CurrentIcon">Current Icon</Label>
      {props.url ? <Image id="CurrentIcon" src={props.url} width={64} height={64} /> : <span>Not set</span>}
    </div>
  );
};

const Archive = (props: {
  isNew: boolean;
  isArchived: boolean;
  onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
}) => {
  if (props.isNew) {
    return null;
  }
  return (
    <>
      <div className="FormRow" style={{ marginTop: '2rem' }}>
        <Checkbox label="Archived" checked={props.isArchived} onChange={props.onChange} />
      </div>

      <div className="FormRow">
        <MessageBar messageBarType={MessageBarType.warning}>
          Warning: archiving an application will hide it from the app launcher application as well as the side draw for all users. Unarchiving an
          application will cause it to reappear for any user that had previously added it to their side draw. If this is not intended, consider
          creating a new application.
        </MessageBar>
      </div>
    </>
  );
};

export default ApplicationForm;
