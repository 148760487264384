import * as React from 'react';
import { TextField, ITextField } from 'office-ui-fabric-react/lib-commonjs/TextField';

interface INumberTextFieldProps {
  id?: string;
  value: number | undefined;
  readOnly?: boolean;
  onChange: (newValue: number | undefined) => void;
}

interface INumberTextFieldState {
  value?: string;
}

export class NumberTextField extends React.Component<INumberTextFieldProps, INumberTextFieldState> {
  private textFieldRef = React.createRef<ITextField>();

  constructor(props: INumberTextFieldProps) {
    super(props);
    this.state = {
      value: props.value === undefined ? '' : props.value.toString()
    };
  }

  public render = () => (
    <div className="NumberTextField">
      <TextField
        id={this.props.id}
        componentRef={this.textFieldRef}
        disabled={this.props.readOnly}
        readOnly={this.props.readOnly}
        className="NumberTextField-textField"
        value={this.getDisplayValue()}
        onChange={this.onChange}
        autoComplete="off"
        onGetErrorMessage={this.validateNumber}
      />
    </div>
  );

  public focus = () => this.textFieldRef.current!.focus();

  public reset = () => this.setState({ value: undefined });

  private getDisplayValue = () => (this.state.value || this.props.value || '').toString();

  private validateNumber = (value: string) => (isNaN(Number(value)) ? 'The value should be a number.' : '');

  private onChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    this.setState({
      value
    });
    const numericValue = Number(value);
    this.props.onChange(isNaN(numericValue) ? undefined : numericValue);
  };
}
