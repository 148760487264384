import { UserAgentApplication, Logger, LogLevel, Configuration, AuthenticationParameters, AuthResponse } from 'msal';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { getCombinedModifierFlags } from 'typescript';
import { fetchConfig, getConfig } from '../config/config';
import { logError, logInfo, logWarning } from '../logging/logging';

const hiddenLoginFrameId = 'applauncher-hidden-login-frame';
const loginCompleteMessage = 'loginComplete';

interface IUserProfile {
  displayName: string;
  isAdmin: boolean;
}

interface IAuthDetails {
  app: UserAgentApplication;
  loginHint: string;
  loginReturnUrl?: string;
  isWidget: boolean;
  authority: string;
  hiddenLoginPromise?: Promise<void>;
}

let receiveMessageHandler: any;

function loggerCallback(logLevel: LogLevel, message: any) {
  if (logLevel === LogLevel.Error) {
    logError(message);
  } else if (logLevel === LogLevel.Warning) {
    logWarning(message);
  } else {
    logInfo(message);
  }
}

const getAuthDetails = (): IAuthDetails => {
  return (window as any).authDetails;
};

const init = (loginHint?: string, loginReturnUrl?: string) => {
  logInfo('Initializing MSAL User Agent');

  const config = getConfig();
  const authorityObj = `https://login.microsoftonline.com/${config.aadTenantId}`;
  const loggerObj = new Logger(loggerCallback, { level: LogLevel.Verbose });
  const msalConfig: Configuration = {
    auth: {
      clientId: config.aadAppId,
      // redirectUri: loginReturnUrl,
      navigateToLoginRequestUrl: false,
      authority: authorityObj
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    },
    system: {
      'logger': loggerObj,
      loadFrameTimeout: 20000,
    },
    framework: {

    }
  }

  if (!!loginReturnUrl) {
    msalConfig.auth.navigateToLoginRequestUrl = true;
    msalConfig.auth.redirectUri = loginReturnUrl;
  }

  const app = new UserAgentApplication(msalConfig);

  (window as any).authDetails = {
    app,
    loginHint,
    loginReturnUrl,
    isWidget: config.isWidget,
    authorityObj
  };

  if (isHiddenLoginFrame()) {
    if (window.location.href.indexOf('#id_token') !== -1) {
      logInfo('Received id token in hidden login frame. Messaging the top window');
      if (top) {
        top.postMessage(loginCompleteMessage, '*');
      }
    } else {
      logInfo('Calling MSAL loginRedirect() inside hidden login frame');

      const responseScopes: string[] = getApplicationScopes();

      const extraQueryParams: StringDict = {
        'domain_hint': 'organizations'
      }

      const authParams: AuthenticationParameters = {
        scopes: responseScopes,
        extraQueryParameters: extraQueryParams,
        loginHint: `${loginHint}`
      }

      app.loginRedirect(authParams);
    }
  }
};

const loginCallback = (errorDesc: string, token: string, error: string, tokenType: string, userState: string) => {
  if (error) {
    logError(`${error}: ${errorDesc}`);
  }
};

const getApplicationScopes = () => [`api://${getConfig().aadAppId}/AppLauncher`];

const isLoggedIn = (): boolean => {
  const authDetails = getAuthDetails();
  const app = authDetails ? authDetails.app : null;
  return app != null && app.getAccount() != null;
};

const login = () => {
  const responseScopes: string[] = getApplicationScopes();
  const authParams: AuthenticationParameters = {
    scopes: responseScopes
  }
  getAuthDetails().app.loginRedirect(authParams);
};

// loginHidden just does a normal msal.loginRedirect(), except it does
// this in a hidden iframe. The idea is that the host app should already
// be authenticated with F5 which means no user interaction is necessary.
const loginHidden = (): Promise<void> => {
  let promise = getAuthDetails().hiddenLoginPromise;

  // Because multiple API requests could ultimately trigger a loginHidden() we don't want
  // to open a bunch of hidden iframes. More importantly all incoming requests must wait
  // for the login to finish before an access token can be acquired. So everyone just
  // waits on the same promise until the login is complete.
  if (promise) {
    logInfo('A hidden login is already in progress. Waiting for it complete...');
    return promise;
  }

  promise = new Promise((resolve, reject) => {
    // loginHidden should only ever be called from the top window. It's
    // an expensive operation and we don't want to call it in every frame
    // that loads the widget.
    if (window.parent !== window) {
      resolve();
      return;
    }

    receiveMessageHandler = receiveMessage(resolve);
    window.addEventListener('message', receiveMessageHandler, false);

    const ifr = document.createElement('iframe');
    ifr.setAttribute('sandbox', 'allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation');
    ifr.setAttribute('id', hiddenLoginFrameId);
    ifr.style.position = 'absolute';
    ifr.style.width = ifr.style.height = '0';
    ifr.style.border = 'none';
    const frame = document.getElementsByTagName('body')[0].appendChild(ifr);

    const authDetails = getAuthDetails();
    if (!authDetails.loginHint) {
      logError('Tried to perform a hidden login, but login hint was not set');
      reject();
    }

    const targetUrl = authDetails.loginReturnUrl || window.location.href.split('?')[0];
    logInfo(`Setting hidden login frame src to ${targetUrl}`);

    // in IE the iframe does not actually load if the URL is the same as the top page!
    // Force it by adding some parameter
    frame.src = `${targetUrl}?applauncher=true`;
  });

  getAuthDetails().hiddenLoginPromise = promise;
  return promise;
};

const isHiddenLoginFrame = (): boolean => {
  return window.frameElement && window.frameElement.id === hiddenLoginFrameId;
};

const receiveMessage = (resolve: () => void) => {
  return (event: any) => {
    if (event.data === loginCompleteMessage) {
      logInfo('Received LoginComplete message. Tearing down hidden frame and resolving request');
      const ifr = document.getElementById(hiddenLoginFrameId);
      if (ifr) {
        document.getElementsByTagName('body')[0].removeChild(ifr);
      }

      window.removeEventListener('message', receiveMessageHandler);
      getAuthDetails().hiddenLoginPromise = undefined;
      resolve();
    }
  };
};

const getAccessToken = (scopes: string[]): Promise<string> => {
  return getAccessTokenInternal(scopes).then(
    (accessToken: string) => accessToken,
    async err => {
      logWarning('Acquiring access token failed. Attempting to login...');

      if (getAuthDetails().isWidget) {
        await loginHidden();
        return await getAccessTokenInternal(scopes);
      } else {
        await login();
      }

      return 'Could not get access token. Logging in again';
    }
  );
};

const getAccessTokenInternal = async (scopes: string[]): Promise<string> => {
  const authDetails = getAuthDetails();
  const loginHintDetails = authDetails.isWidget ? `${authDetails.loginHint}` : '';



  const tokenAuthParams: AuthenticationParameters = {
    'scopes': scopes,
    authority: authDetails.authority,
    loginHint: loginHintDetails
  }

  try {
    const tokenResponse: AuthResponse = await authDetails.app.acquireTokenSilent(tokenAuthParams);
    return tokenResponse.accessToken
  } catch (e) {
    logWarning(e);
    // acquireTokenSilent can fail in some situations, for example Safari 12+ ITP stops sending cookies
    // when there is no user interaction. For now in the Main App use acquireTokenRedirect as
    // a fallback. We may extend this to the Side Draw too.

    const authParams: AuthenticationParameters = {
      'scopes': scopes,
      authority: authDetails.authority
    }

    if (!authDetails.isWidget) {
      logWarning('acquireTokenSilent() failed. Trying to get the access token with a redirect...');
      authDetails.app.acquireTokenRedirect(authParams);
      return '';
    } else {
      throw e;
    }
  }
};

const getUserProfile = (): IUserProfile | null => {
  if (!isLoggedIn()) {
    return null;
  }

  const user = getAuthDetails().app.getAccount().idToken as any;
  return {
    displayName: user.name,
    isAdmin: ((user.roles as []) || []).some(r => r === 'Admin')
  };
};

const isCallbackRoute = (): boolean => {
  return getAuthDetails().app.isCallback(window.location.hash);
};

const ensureWidgetAuthenticated = async (loginHint: string, loginReturnUrl?: string): Promise<boolean> => {
  await fetchConfig();
  if (!loginHint) {
    const config = getConfig();
    loginHint = config.loginHint || '';
  }

  console.log('Widget logging in for ' + loginHint)

  await init(loginHint, loginReturnUrl);
  // when we are on an #id_token route return false and don't try to login again
  if (isCallbackRoute()) {
    return true;
  }
  if (!isLoggedIn()) {
    await loginHidden();
  }

  return true;
};

const ensureAppAuthenticated = async (): Promise<boolean> => {
  await fetchConfig();
  await init();
  // when we are on an #id_token route return false and don't try to login again
  if (isCallbackRoute()) {
    return true;
  }
  if (!isLoggedIn()) {
    await login();
    return false;
  }

  return true;
};

export const auth = {
  ensureWidgetAuthenticated,
  ensureAppAuthenticated,
  isLoggedIn,
  getApplicationScopes,
  getUserProfile,
  getAccessToken
};
