import React, { Component } from 'react';
import { DetailsList, IColumn, SelectionMode } from 'office-ui-fabric-react/lib-commonjs/DetailsList';
import { Checkbox } from 'office-ui-fabric-react/lib-commonjs/Checkbox';
import { IDetailedApplicationUrl } from '../ApplicationUrls/ApplicationUrls';
import LazyTextField from '../LazyTextField/LazyTextField';
import { sortByColumn } from '../../../shared/util/details-list-util';

interface IApplicationLocationSpecificUrlsProps {
  urls: IDetailedApplicationUrl[];
  onChange: (newUrls: IDetailedApplicationUrl[]) => void;
}

interface IApplicationLocationSpecificUrlsState {
  columns: IColumn[];
}

class ApplicationLocationSpecificUrls extends Component<IApplicationLocationSpecificUrlsProps, IApplicationLocationSpecificUrlsState> {
  constructor(props: IApplicationLocationSpecificUrlsProps) {
    super(props);
    this.state = {
      columns: this.getColumns()
    };
  }

  public render() {
    return (
      <div className="FormRow">
        <DetailsList items={this.props.urls} columns={this.state.columns} selectionMode={SelectionMode.none} compact={true} getKey={this.getRowKey} />
      </div>
    );
  }

  private getColumns(): IColumn[] {
    return [
      {
        key: 'isActive',
        name: 'Active',
        fieldName: 'isActive',
        minWidth: 80,
        maxWidth: 80,
        isResizable: false,
        onColumnClick: this.onColumnClick,
        onRender: (url: IDetailedApplicationUrl, index?: number) => (
          <Checkbox checked={url.isActive} onChange={this.onLocationEnableHandlerCreator(index)} />
        )
      },
      {
        key: 'location.locationCode',
        name: 'Code',
        fieldName: 'location.locationCode',
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: (url: IDetailedApplicationUrl) => <div>{url.location ? url.location.locationCode : ''}</div>
      },
      {
        key: 'location.suburb',
        name: 'Suburb',
        fieldName: 'location.suburb',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: (url: IDetailedApplicationUrl) => <div>{url.location ? url.location.suburb : ''}</div>
      },
      {
        key: 'location.displayName',
        name: 'Location',
        fieldName: 'location.displayName',
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: (url: IDetailedApplicationUrl) => <div>{url.location ? url.location.displayName : ''}</div>
      },
      {
        key: 'url',
        name: 'URL',
        fieldName: 'url',
        minWidth: 300,
        maxWidth: 800,
        isResizable: true,
        onColumnClick: this.onColumnClick,
        onRender: (url: IDetailedApplicationUrl, index?: number) => {
          return <LazyTextField value={url.url} disabled={!url.isActive} onTextChange={this.onUrlChangeHandlerCreator(index)} />;
        }
      }
    ];
  }

  private onLocationEnableHandlerCreator = (index?: number) => (_?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    if (!index && index !== 0) {
      return;
    }

    const newUrls = this.props.urls.slice();
    const urlToReplace = newUrls[index];
    newUrls.splice(index, 1, { ...urlToReplace, isActive: isChecked || false });
    this.props.onChange(newUrls);
  };

  private onUrlChangeHandlerCreator = (index?: number) => (url?: string) => {
    if (!index && index !== 0) {
      return;
    }

    const newUrls = this.props.urls.slice();
    const urlToReplace = newUrls[index];
    newUrls.splice(index, 1, { ...urlToReplace, url: url || '' });
    this.props.onChange(newUrls);
  };

  private onColumnClick = (_: React.MouseEvent<HTMLElement>, column: IColumn) => {
    this.sort(column.key);
  };

  private sort(columnKey: string) {
    const sortResult = sortByColumn(this.props.urls, this.state.columns, columnKey);
    this.setState({ columns: sortResult.columns });
    this.props.onChange(sortResult.rows);
  }

  private getRowKey(item: IDetailedApplicationUrl): string {
    return item.location!.locationCode;
  }
}

export default ApplicationLocationSpecificUrls;
