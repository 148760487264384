import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { auth } from '../../../shared/auth/auth';

interface IPrivateRouteProps extends RouteProps {
  component: any;
}

export const AdminRoute = (props: IPrivateRouteProps) => {
  const { component: Comp, ...rest } = props;
  return (
    <div className="Admin">
      <Route
        {...rest}
        render={p => {
          const profile = auth.getUserProfile();
          // tslint:disable-next-line jsx-no-lambda
          return profile && profile.isAdmin ? <Comp {...p} /> : <Redirect to={{ pathname: '/' }} />;
        }}
      />
    </div>
  );
};

export const UserRoute = (props: RouteProps) => (
  <div className="User">
    <Route {...props} />
  </div>
);
