import React, { Component } from 'react';
import { IconButton, DefaultButton, ActionButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { Icon } from 'office-ui-fabric-react/lib-commonjs/Icon';
import './HelpButton.scss';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/Callout';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import { IPoint } from 'office-ui-fabric-react/lib-commonjs/Utilities';
import { EventBus, EventNames } from '../../../shared/eventbus/eventbus';

interface IHelpButtonProps {
  className?: string;
}

interface IHelpButtonState {
  isCalloutVisible: boolean;
  helpButtonId: string;
}

export default class HelpButton extends Component<IHelpButtonProps, IHelpButtonState> {
  private static instanceCounter = 0;

  constructor(props: IHelpButtonProps) {
    super(props);
    HelpButton.instanceCounter++;
    this.state = {
      helpButtonId: `HelpButton${HelpButton.instanceCounter}`,
      isCalloutVisible: false
    };
  }

  public render = () => (
    <>
      <DefaultButton
        className={`HelpButton ${this.props.className}`}
        title="Help"
        id={this.state.helpButtonId}
        ariaLabel="StatusCircleQuestionMark"
        onClick={this.showCallout}
      >
        <Icon iconName="Help" />
      </DefaultButton>
      <Callout
        className="HelpCallout"
        target={`#${this.state.helpButtonId}`}
        directionalHint={DirectionalHint.bottomCenter}
        onDismiss={this.dismissCallout}
        setInitialFocus={true}
        hidden={!this.state.isCalloutVisible}
      >
        <header>BCE Help</header>
        <hr />
        <Link href="https://bce.service-now.com" onClick={this.dismissCallout}>
          <Icon iconName="FileSymlink" />
          <span className="LinkText">Help Centre</span>
        </Link>
        <Link onClick={this.startAppTour}>
          <Icon iconName="MSNVideos" />
          <span className="LinkText">Show Screen Tour</span>
        </Link>
        <IconButton className="CloseHelpButton" iconProps={{ iconName: 'Cancel' }} onClick={this.dismissCallout} />
      </Callout>
    </>
  );

  private startAppTour = () => {
    this.dismissCallout();
    EventBus.emit(EventNames.StartApplicationTour);
  };

  private showCallout = () => this.setState({ isCalloutVisible: true });

  private dismissCallout = () => this.setState({ isCalloutVisible: false });
}
