import { AppSearchFeature } from './Features/AppSearchFeature';
import { ByCategoryTabFeature } from './Features/ByCategoryTabFeature';
import { AppDescriptionIconFeature } from './Features/AppDescriptionIconFeature';
import { SearchAllSitesFeature } from './Features/SearchAllSitesFeature';
import { HelpFeature } from './Features/HelpFeature';
import { SideDrawButtonFeature } from './Features/SideDrawButtonFeature';
import { SideDrawEditButtonFeature } from './Features/SideDrawEditButtonFeature';
import { AddRemoveAppButtonFeature } from './Features/AddRemoveAppButtonFeature';
import { IFeatureToShow } from './IFeatureToShow';

export const featuresToShow: IFeatureToShow[] = [
  AppSearchFeature,
  ByCategoryTabFeature,
  AppDescriptionIconFeature,
  SearchAllSitesFeature,
  HelpFeature,
  SideDrawButtonFeature,
  SideDrawEditButtonFeature,
  AddRemoveAppButtonFeature
];
