import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { EventBus, EventNames } from '../../../../shared/eventbus/eventbus';
import { MinimumLargeScreenWidth, MinimumMediumScreenWidth, IFeatureToShow } from '../IFeatureToShow';

const getSelector = () => {
  if (window.matchMedia(`(min-width: ${MinimumLargeScreenWidth}px)`).matches) {
    return '.HelpButton.LargeScreen';
  } else if (window.matchMedia(`(min-width: ${MinimumMediumScreenWidth}px)`).matches) {
    return '.HelpButton.MediumScreen';
  } else {
    return '.HelpButton.SmallScreen';
  }
};

export const HelpFeature: IFeatureToShow = {
  getSelector,
  directionalHint: DirectionalHint.bottomCenter,
  title: 'Help',
  description: `Use help to navigate to ServiceNow or to turn this screen tour back on later`,
  featureDidShowUp: () => {
    const element = document.querySelector(getSelector());
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    if (!window.matchMedia(`(min-width: ${MinimumMediumScreenWidth}px)`).matches) {
      EventBus.emit(EventNames.MakeSureHelpIsVisible);
    }
  }
};
