import { Api, IApiResponse } from '../api';
import { CategoryDto, CategoryEntryDto } from '../models';

export const getAll = (): Promise<IApiResponse<CategoryDto[]>> => Api.get('Categories');

export const add = (category: CategoryEntryDto) => Api.post('Categories', category);

export const update = (id: string, category: CategoryEntryDto) => Api.put(`Categories/${id}`, category);

export const remove = (id: string) => Api.delete(`Categories/${id}`);
