import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { EventBus, EventNames } from '../../../../shared/eventbus/eventbus';
import { MinimumLargeScreenWidth, MinimumMediumScreenWidth, showNextFeature, IFeatureToShow } from '../IFeatureToShow';

const getSelector = () => {
  if (window.matchMedia(`(min-width: ${MinimumLargeScreenWidth}px)`).matches) {
    return '.WidgetAppButton.LargeScreen';
  } else if (window.matchMedia(`(min-width: ${MinimumMediumScreenWidth}px)`).matches) {
    return '.WidgetAppButton.MediumScreen';
  } else {
    return '.WidgetAppButton.SmallScreen';
  }
};

export const SideDrawButtonFeature: IFeatureToShow = {
  getSelector,
  directionalHint: DirectionalHint.bottomCenter,
  title: 'BCE Apps',
  description: `Wherever you see this button, you will be able to open your BCE app launcher and quickly navigate to other BCE apps. Try it now.`,
  unblockInteractionWithTheAppAfterCoachmarkOpens: true,
  hideNextButton: false,
  featureDidShowUp: () => {
    const element = document.querySelector(getSelector());
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    EventBus.subscribe(EventNames.UserOpenedSideDraw, showNextFeature);
    if (!window.matchMedia(`(min-width: ${MinimumMediumScreenWidth}px)`).matches) {
      EventBus.emit(EventNames.MakeSureHelpIsVisible);
    }
  },
  featureWillHide: () => {
    EventBus.unsubscribe(EventNames.UserOpenedSideDraw, showNextFeature);
    EventBus.emit(EventNames.OpenSideDraw);
  }
};
