import React, { Component, createRef } from 'react';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { Label } from 'office-ui-fabric-react/lib-commonjs/Label';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import { NumberTextField } from '../../../shared/components/NumberTextField/NumberTextField';
import SaveButton from '../SaveButton/SaveButton';
import { DefaultButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import * as Configuration from '../../../shared/api/configuration/configuration';
import { toast } from 'react-toastify';

interface IConfigurationFormDto {
  sideDrawAppCountLimit: number | undefined;
}

interface IAdminConfigurationState {
  form: IConfigurationFormDto;
  isSubmitting: boolean;
  isInEditMode: boolean;
  workTracker: WorkTracker;
}

class AdminConfiguration extends Component<any, IAdminConfigurationState> {
  private readonly sideDrawAppCountLimitRef = createRef<NumberTextField>();

  constructor(props: any) {
    super(props);
    this.state = {
      form: { sideDrawAppCountLimit: undefined },
      isSubmitting: false,
      isInEditMode: false,
      workTracker: new WorkTracker()
    };
  }

  public render = () => (
    <LoadingPanel workTracker={this.state.workTracker}>
      <DefaultButton text="Edit" onClick={this.enterEditMode} disabled={this.state.isInEditMode} />
      <form className="Form" style={{ marginTop: '1rem' }}>
        <div className="FormRow">
          <Label required={true} htmlFor="SideDrawAppCountLimit">
            Side Draw Application Count Limit
          </Label>
          <NumberTextField
            id="SideDrawAppCountLimit"
            ref={this.sideDrawAppCountLimitRef}
            readOnly={!this.state.isInEditMode}
            value={this.state.form === undefined ? undefined : this.state.form.sideDrawAppCountLimit}
            onChange={this.onSideDrawAppCountLimitChanged}
          />
        </div>
        <div className="FormFooter">
          {this.state.isInEditMode && (
            <>
              <SaveButton isSubmitting={this.state.isSubmitting} isFormValid={this.isFormValid()} submitForm={this.submitForm} />
              <DefaultButton text="Cancel" onClick={this.leaveEditMode} style={{ marginLeft: '0.5rem' }} />
            </>
          )}
        </div>
      </form>
    </LoadingPanel>
  );

  public componentDidMount = async () => {
    this.state.workTracker.track(this.loadData());
  };

  public componentDidUpdate = (prevProps: any, prevState: IAdminConfigurationState) => {
    if (!prevState.isInEditMode && this.state.isInEditMode) {
      if (this.sideDrawAppCountLimitRef.current !== null) {
        this.sideDrawAppCountLimitRef.current!.focus();
      }
    }
  };

  private leaveEditMode = () => {
    this.setState({ isInEditMode: false });
    this.sideDrawAppCountLimitRef.current!.reset();
    this.state.workTracker.track(this.loadData());
  };

  private enterEditMode = () => {
    this.setState({ isInEditMode: true });
  };

  private loadData = async () => {
    const response = await Configuration.get();
    if (response.success) {
      this.setState({ form: { sideDrawAppCountLimit: response.result!.sideDrawAppCountLimit } });
    }
  };

  private isFormValid = () =>
    this.state.form.sideDrawAppCountLimit !== undefined &&
    this.state.form.sideDrawAppCountLimit > 0 &&
    this.state.form.sideDrawAppCountLimit === parseInt(this.state.form.sideDrawAppCountLimit.toString(), 10);

  private submitForm = async (): Promise<boolean> => {
    this.setState({ isSubmitting: true });
    const response = await Configuration.update({ sideDrawAppCountLimit: this.state.form.sideDrawAppCountLimit! });
    this.setState({ isSubmitting: false });
    if (response.success) {
      toast.success('Configuration was successfully saved');
      this.setState({ isInEditMode: false });
    }
    return response.success;
  };

  private onSideDrawAppCountLimitChanged = (sideDrawAppCountLimit: number | undefined) =>
    this.setState({ form: { ...this.state.form, sideDrawAppCountLimit } });
}

export default AdminConfiguration;
