import { Api, IApiResponse } from '../api';
import { ApplicationDto, ApplicationEntryDto, ApplicationUrlDto, CategoryDto } from '../models';

export interface ILocationApplication {
  id: string;
  appId: string;
  appName: string;
  description: string;
  moreInformationUrl: string;
  isDescriptionVisible: boolean;
  locationCode?: string | null;
  url: string;
  iconUrl: string;
  isArchived: boolean;
  tags: string[];
  categories: CategoryViewModel[];
}

export interface IApplicationLocationsAndCategories {
  applications: ILocationApplication[];
  categoriesById: { [categoryId: string]: CategoryViewModel };
}

export class CategoryViewModel {
  private collapsed = false;
  constructor(private category: CategoryDto) {}

  public get name() {
    return this.category.name;
  }

  public get id() {
    return this.category.id;
  }

  public get isCollapsed() {
    return this.collapsed;
  }

  public toggle() {
    this.collapsed = !this.collapsed;
  }
}

const generateCategoryViewModels = (applications:ApplicationDto[]) => {
    return applications.reduce((categoryVmById: { [categoryId: string]: CategoryViewModel }, item) => {
        item.categories.filter(c => !categoryVmById[c.id]).map(newCategory => (categoryVmById[newCategory.id] = new CategoryViewModel(newCategory)));
        return categoryVmById;
    }, {});
};


const getCategoryViews = (categories: CategoryDto[], categoryViewModels : {[categoryId: string]: CategoryViewModel }) : CategoryViewModel[] => {
    return categories.map(category => categoryViewModels[category.id]);
 }

const getMine = async (all?: boolean, accessToken?:string): Promise<IApiResponse<ApplicationDto[]>> => Api.get(`apps/myapps`,accessToken);

const getMineFlat = async (accessToken?:string): Promise<IApiResponse<IApplicationLocationsAndCategories>> => {
  const response = await getMine(false,accessToken);
  if (!response.success || !response.result) {
    return { success: false };
  }
  const applications = response.result;
  const categoryViewModels = generateCategoryViewModels(applications);
  const myApplications: ILocationApplication [] = [];
  applications.forEach((item) =>{
    const app = item.urls.map(
      (url: ApplicationUrlDto): ILocationApplication => ({
        id: `${item.id}-${url.locationCode ? url.locationCode : 'global'}`,
        appId: item.id,
        appName: item.urls.length > 1 ? `${item.name} (${url.locationName})` : item.name,
        description: item.description,
        moreInformationUrl: item.moreInformationUrl,
        isDescriptionVisible: false,
        url: url.url,
        locationCode: url.locationCode,
        iconUrl: item.iconUrl,
        isArchived: item.isArchived,
        tags: item.tags,
        categories: getCategoryViews(item.categories, categoryViewModels)
      })
    )
    myApplications.push(app[0]);
  });

  return { success: true, result: { applications: myApplications, categoriesById: categoryViewModels } };
 }

const getMany = (all?: boolean, accessToken?:string): Promise<IApiResponse<ApplicationDto[]>> => Api.get(`Apps?all=${all || false}`,accessToken);

const getManyFlat = async (accessToken?:string): Promise<IApiResponse<IApplicationLocationsAndCategories>> => {
  const response = await getMany(false,accessToken);
  if (!response.success || !response.result) {
    return { success: false };
  }

  const applications = response.result;

  const categoryViewModels = generateCategoryViewModels(applications);

  const locationApplications = applications.reduce(
    (list: ILocationApplication[], item: ApplicationDto) =>
      list.concat(
        item.urls.map(
          (url: ApplicationUrlDto): ILocationApplication => ({
            id: `${item.id}-${url.locationCode ? url.locationCode : 'global'}`,
            appId: item.id,
            appName: item.urls.length > 1 ? `${item.name} (${url.locationName})` : item.name,
            description: item.description,
            moreInformationUrl: item.moreInformationUrl,
            isDescriptionVisible: false,
            url: url.url,
            locationCode: url.locationCode,
            iconUrl: item.iconUrl,
            isArchived: item.isArchived,
            tags: item.tags,
            categories: getCategoryViews(item.categories, categoryViewModels)
          })
        )
      ),
    []
  );

  return { success: true, result: { applications: locationApplications, categoriesById: categoryViewModels } };
};

const get = (id: string): Promise<IApiResponse<ApplicationDto>> => Api.get(`Apps/${id}`);

const add = (application: ApplicationEntryDto, iconFile: File | undefined) => Api.post('Apps', application, iconFile ? [iconFile] : []);

const update = (id: string, application: ApplicationEntryDto, iconFile: File | undefined) =>
  Api.put(`Apps/${id}`, application, iconFile ? [iconFile] : []);

const filterApplications = (applications: ILocationApplication[] | null, searchPhrase: string | null): ILocationApplication[] => {
  if (!applications || !searchPhrase) {
    return applications || [];
  }

  const searchTermLowerCase = searchPhrase.toLowerCase();
  return applications
    .filter(
      a =>
        a.appName.toLowerCase().indexOf(searchTermLowerCase) >= 0 ||
        a.tags.some(t => t.toLowerCase().indexOf(searchTermLowerCase) >= 0) ||
        a.categories.some(c => c.name.toLowerCase().indexOf(searchTermLowerCase) >= 0)
    )
    .sort((c1, c2) => c1.appName.toLowerCase().localeCompare(c2.appName.toLowerCase()));
};

export { getMany, getManyFlat, getMineFlat,  get, add, update, filterApplications };
