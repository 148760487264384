import { ILocationApplication } from '../api/applications/applications';
import { getConfig } from '../config/config';

/* NOTE:
 *  The module 'applicationinsights-js' is causing the following issue during unit testing:
 *  "ReferenceError: define is not defined"
 *  It's a know issue and authors are working on this. Once it's fixed please change the work-around
 *  of (await import('applicationinsights-js')).AppInsights to a regular import at the top of the file.
 */

const reportAppUsage = async (app: ILocationApplication, userProfiles: string[], source: 'SideDraw' | 'MainApp') => {
  const AppInsights = (await import('applicationinsights-js')).AppInsights;
  AppInsights.trackEvent('Application Usage', {
    applicationName: app.appName,
    locationCode: app.locationCode || '',
    userProfiles: userProfiles.join(', '),
    source
  });
};

const initialiseAnalyticsEngine = async () => {
  const AppInsights = (await import('applicationinsights-js')).AppInsights;
  AppInsights.downloadAndSetup!({ instrumentationKey: getConfig().applicationInsightsInstrumentationKey });
};

export { reportAppUsage, initialiseAnalyticsEngine };
