import { IColumn } from 'office-ui-fabric-react/lib-commonjs/DetailsList';
import { orderBy } from 'lodash';

interface IColumnSortResult<T> {
  columns: IColumn[];
  rows: T[];
}

const sortByColumn = <T>(rows: T[], columns: IColumn[], sortColumnKey: string): IColumnSortResult<T> => {
  let isSortedDescending = false;
  for (const column of columns) {
    if (column.key === sortColumnKey) {
      column.isSorted = true;
      column.isSortedDescending = !column.isSortedDescending;
      isSortedDescending = column.isSortedDescending;
    } else {
      column.isSorted = false;
      column.isSortedDescending = true;
    }
  }

  const sortedRows = copyAndSort(rows, sortColumnKey, isSortedDescending);

  return { columns, rows: sortedRows };
};

const copyAndSort = <T>(rows: T[], field: string, isSortedDescending: boolean): T[] => {
  return orderBy(rows.slice(0), [row => getSort(row, field)], [isSortedDescending ? 'desc' : 'asc']) as T[];
};

const getSort = <T>(item: T, field: string) => {
  const prop = getNested(field, item);
  if (prop && typeof prop === 'string') {
    return prop.toUpperCase();
  }

  return prop;
};

const getNested = (prop: string, obj: any): any => {
  const props = prop.split('.');
  return props.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
};

export { sortByColumn };
