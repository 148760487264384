import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { Link } from 'office-ui-fabric-react/lib-commonjs/Link';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export enum RouteLinkType {
  Link = 'link',
  DefaultButton = 'defaultButton',
  PrimaryButton = 'primaryButton'
}

interface IRouteLinkProps {
  to: string;
  action?: (() => boolean) | (() => Promise<boolean>);
  disabled?: boolean;
  linkType: RouteLinkType;
  style?: {};
}

class RouteLinkClass extends Component<RouteComponentProps<any> & IRouteLinkProps, any> {
  constructor(props: RouteComponentProps<any> & IRouteLinkProps) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  public render() {
    const { to, disabled, linkType, style } = this.props;
    const linkTags = {
      link: Link,
      defaultButton: DefaultButton,
      primaryButton: PrimaryButton
    };

    const LinkTag = linkTags[linkType] as any;
    return (
      <LinkTag href={to} onClick={this.clickHandler} disabled={disabled} style={style}>
        {this.props.children}
      </LinkTag>
    );
  }

  private clickHandler(ev: React.MouseEvent<HTMLButtonElement>) {
    const route = ev.currentTarget.getAttribute('href') || '#';
    ev.preventDefault();

    const actionResult = this.props.action ? this.props.action() : true;
    if (actionResult instanceof Promise) {
      actionResult.then(success => {
        if (success) {
          this.props.history.push(route);
        }
      });
    } else if (actionResult) {
      this.props.history.push(route);
    }
  }
}

const RouteLink = withRouter(RouteLinkClass);

export default RouteLink;
