import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../../../shared/auth/auth';
import Admin from '../../components/Admin/Admin';
import ApplicationForm from '../../components/ApplicationForm/ApplicationForm';
import Apps from '../../components/Apps/Apps';
import { AdminRoute, UserRoute } from '../../components/CustomRoutes/CustomRoutes';
import NotFound from '../NotFound/NotFound';
import CategoryForm from '../../components/Categories/CategoryForm';
import TopBar from '../../components/TopBar/TopBar';
import './MainApp.scss';
import { AppTour } from '../../components/AppTour/AppTour';

export enum AppTabKeys {
  Category = 'category',
  AZ = 'az'
}

interface IMainAppState {
  activeAppTab: AppTabKeys;
}

class MainApp extends Component<{}, IMainAppState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      activeAppTab: AppTabKeys.Category
    };
  }

  public render() {
    return !auth.isLoggedIn() ? null : (
      <BrowserRouter basename="/">
        <div className="AppContainer">
          <ToastContainer />
          <AppTour activeTab={this.state.activeAppTab} />
          <TopBar />
          <header>Applications</header>
          <Switch>
            <UserRoute exact={true} path={`(/|/${AppTabKeys.AZ}|/${AppTabKeys.Category})`} component={this.renderApps} />
            <AdminRoute path="/admin" exact={true} component={Admin} />
            <AdminRoute path="/admin/applications/:id" component={ApplicationForm} />
            <AdminRoute path="/admin/categories/:id" component={CategoryForm} />
            <Route path="**" component={NotFound} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  private renderApps = () => <Apps activeTab={this.state.activeAppTab} onSetActiveTab={this.setActiveAppTab} />;

  private setActiveAppTab = (activeAppTab: AppTabKeys) => this.setState({ activeAppTab });
}

export default MainApp;
