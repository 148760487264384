import { debounce, sortBy } from 'lodash';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib-commonjs/Pivot';
import { SearchBox } from 'office-ui-fabric-react/lib-commonjs/SearchBox';
import React, { Component } from 'react';
import { ILocationApplication } from '../../../shared/api/applications/applications';
import * as Applications from '../../../shared/api/applications/applications';
import * as UserPreferences from '../../../shared/api/applications/userPreferences';
import * as Configuration from '../../../shared/api/configuration/configuration';
import { filterApplications } from '../../../shared/api/applications/applications';
import * as Profiles from '../../../shared/api/profiles/profiles';
import AppsByCategory from './AppsByCategory';
import AppsSorted from './AppsSorted';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { RouteComponentProps, withRouter } from 'react-router';
import './Apps.scss';
import { UserFavoriteAppDto } from '../../../shared/api/models';
import { EventBus, EventNames } from '../../../shared/eventbus/eventbus';
import { toast } from 'react-toastify';
import { AppTabKeys } from '../../routes/MainApp/MainApp';

interface IAppProps {
  activeTab: AppTabKeys;
  onSetActiveTab: (activeTab: AppTabKeys) => void;
}

interface IAppsState {
  searchPhrase: string | null;
  applications: ILocationApplication[] | null;
  favoriteApps: UserFavoriteAppDto[];
  favoriteAppsByKey: { [uniqueKey: string]: UserFavoriteAppDto };
  favoriteAppsLimit: number;
  categoriesById: { [categoryId: string]: Applications.CategoryViewModel };
  userProfiles: string[];
  workTracker: WorkTracker;
}

export interface IApplicationOperations {
  onToggleDescription: (app: ILocationApplication) => void;
  isFavorite: (app: ILocationApplication) => boolean;
  canAddToFavorites: () => boolean;
  onToggleBelongingnessToFavorites: (app: ILocationApplication) => void;
}

class AppsClass extends Component<RouteComponentProps<any> & IAppProps, IAppsState> implements IApplicationOperations {
  constructor(props: RouteComponentProps<any> & IAppProps) {
    super(props);
    this.state = {
      searchPhrase: null,
      categoriesById: {},
      applications: null,
      favoriteApps: [],
      favoriteAppsByKey: {},
      favoriteAppsLimit: 0,
      userProfiles: [],
      workTracker: new WorkTracker()
    };
  }

  public componentDidMount = async () => {
    if (this.props.location.pathname === `/${AppTabKeys.AZ}`) {
      this.props.onSetActiveTab(AppTabKeys.AZ);
    }
    await this.state.workTracker.track(
      Promise.all([this.loadApplications(), this.loadFavouriteApps(), this.loadFavouriteAppsLimit(), this.loadMyProfiles()])
    );
    EventBus.emit(EventNames.MainApplicationLoaded);
    EventBus.subscribe(EventNames.FavouriteAppListModified, this.onFavouriteAppsModified);
  };

  public componentWillUnmount = () => EventBus.unsubscribe(EventNames.FavouriteAppListModified, this.onFavouriteAppsModified);

  public render = () => (
    <div className="AppList">
      <div className="ms-Grid ModeSelector" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div
              id="AppSearchBox"
              style={{ float: 'left', backgroundColor: 'white', paddingLeft: '0.5rem', paddingRight: '0.5rem', marginLeft: '2rem' }}
            >
              <SearchBox placeholder="Search" onChange={this.onSearchPhraseChanged} />
            </div>
            <Pivot onLinkClick={this.viewChanged} style={{ float: 'left', marginLeft: '0.5rem' }} selectedKey={this.props.activeTab}>
              <PivotItem headerButtonProps={{ 'data-tab-button-id': 'ByCategoryButton' }} linkText="By Category" itemKey={AppTabKeys.Category} />
              <PivotItem linkText="A to Z" itemKey={AppTabKeys.AZ} />
            </Pivot>
          </div>
        </div>
      </div>

      <LoadingPanel workTracker={this.state.workTracker}>
        {this.props.activeTab === AppTabKeys.AZ && (
          <AppsSorted userProfiles={this.state.userProfiles} applications={this.getFilteredApplications()} applicationOperations={this} />
        )}
        {this.props.activeTab === AppTabKeys.Category && (
          <AppsByCategory
            userProfiles={this.state.userProfiles}
            applications={this.getFilteredApplications()}
            onToggleCategory={this.toggleCategory}
            onOpenAllCategories={this.openAllCategories}
            onCloseAllCategories={this.closeAllCategories}
            applicationOperations={this}
          />
        )}

        <footer>
          <h1>Need help?</h1>
          <div>For all other Online Services help go to ServiceNow</div>
          <div>or contact the service desk on (07) 3033 7777</div>
        </footer>
      </LoadingPanel>
    </div>
  );

  public onToggleDescription = (app: ILocationApplication) => {
    const foundApp = this.state.applications!.find(a => a.id === app.id);
    if (foundApp) {
      foundApp.isDescriptionVisible = !foundApp.isDescriptionVisible;
      this.setState({ applications: this.state.applications });
    }
  };

  public isFavorite = (app: ILocationApplication) => {
    const appKey = this.makeFavoriteAppKey(app);
    return !!this.state.favoriteAppsByKey[appKey];
  };

  public canAddToFavorites = () => this.state.favoriteApps.length < this.state.favoriteAppsLimit;

  public onToggleBelongingnessToFavorites = async (app: ILocationApplication) => {
    if (this.isFavorite(app)) {
      const newFavAppsList = this.state.favoriteApps.filter(favApp => this.makeFavoriteAppKey(favApp) !== this.makeFavoriteAppKey(app));
      await this.updateFavoriteApps(newFavAppsList);
      this.sendToastNotification(`${app.appName} has been removed from your app launcher`);
    } else {
      const newFavApp: UserFavoriteAppDto = {
        appId: app.appId,
        locationCode: app.locationCode
      };
      const newFavAppsList = [...this.state.favoriteApps, newFavApp];
      await this.updateFavoriteApps(newFavAppsList);
      this.sendToastNotification(`${app.appName} has been added to your app launcher`);
    }
  };

  private updateFavoriteApps = async (newFavAppsList: UserFavoriteAppDto[]) => {
    const result = await this.state.workTracker.queueTrackingAndChain(UserPreferences.update({ favoriteApps: newFavAppsList }));
    if (result.success) {
      EventBus.emit(EventNames.FavouriteAppListModified, newFavAppsList);
    }
  };

  private onFavouriteAppsModified = (newFavAppsList: UserFavoriteAppDto[]) => {
    this.setState({
      favoriteApps: newFavAppsList,
      favoriteAppsByKey: newFavAppsList.reduce((map: { [idAndLocationKey: string]: UserFavoriteAppDto }, favApp) => {
        map[this.makeFavoriteAppKey(favApp)] = favApp;
        return map;
      }, {})
    });
  };

  private sendToastNotification = (message: string) =>
    toast(message, {
      className: 'ToggleAppToast',
      progressClassName: 'ToggleAppToastProgressBar'
    });

  private async loadMyProfiles() {
    const profilesResponse = await Profiles.getMine();
    if (profilesResponse.success) {
      this.setState({ userProfiles: profilesResponse.result!.map(p => p.profileCode) });
    }
  }

  private toggleCategory = (categoryId: string) => {
    const foundCategory = this.state.categoriesById[categoryId];
    if (foundCategory) {
      foundCategory!.toggle();
    }
    this.setState({
      categoriesById: this.state.categoriesById
    });
  };

  private openAllCategories = () => {
    Object.keys(this.state.categoriesById)
      .map(id => this.state.categoriesById[id])
      .forEach(category => {
        if (category.isCollapsed) {
          category.toggle();
        }
      });
    this.setState({
      categoriesById: this.state.categoriesById
    });
  };

  private closeAllCategories = () => {
    Object.keys(this.state.categoriesById)
      .map(id => this.state.categoriesById[id])
      .forEach(category => {
        if (!category.isCollapsed) {
          category.toggle();
        }
      });
    this.setState({
      categoriesById: this.state.categoriesById
    });
  };

  private async loadApplications() {
    const response = await Applications.getManyFlat();
    if (response.success) {
      this.setState({
        applications: sortBy(response.result!.applications, (a: ILocationApplication) => a.appName.toUpperCase()),
        categoriesById: response.result!.categoriesById
      });
    }
  }

  private makeFavoriteAppKey = (favApp: UserFavoriteAppDto) => `${favApp.appId}|${favApp.locationCode}`;

  private loadFavouriteApps = async () => {
    const response = await UserPreferences.get();
    if (response.success) {
      this.onFavouriteAppsModified(response.result!.favoriteApps);
    }
  };

  private loadFavouriteAppsLimit = async () => {
    const response = await Configuration.get();
    if (response.success) {
      this.setState({
        favoriteAppsLimit: response.result!.sideDrawAppCountLimit
      });
    }
  };

  private getFilteredApplications(): ILocationApplication[] {
    return filterApplications(this.state.applications, this.state.searchPhrase);
  }

  private readonly viewChanged = (item?: PivotItem) => {
    if (item) {
      this.switchToView(item.props.itemKey!);
    }
  };

  private switchToView(tabKey: string) {
    if (tabKey === AppTabKeys.AZ) {
      this.props.onSetActiveTab(AppTabKeys.AZ);
      this.props.history.replace(`/${AppTabKeys.AZ}`);
    } else {
      this.props.onSetActiveTab(AppTabKeys.Category);
      this.props.history.replace(`/${AppTabKeys.Category}`);
    }
  }

  /* tslint:disable-next-line */
  private onSearchPhraseChanged = debounce((searchPhrase: string) => {
    Object.keys(this.state.categoriesById).forEach(id => {
      const category = this.state.categoriesById[id];
      if (category.isCollapsed) {
        category.toggle();
      }
    });
    this.setState({ searchPhrase, categoriesById: this.state.categoriesById });
  }, 100);
}

const Apps = withRouter(AppsClass);

export default Apps;
