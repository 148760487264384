import { TextField, ITextFieldProps } from 'office-ui-fabric-react/lib-commonjs/TextField';
import React, { Component } from 'react';
import { debounce } from 'lodash';

interface ILazyTextFieldProps extends ITextFieldProps {
  onTextChange: (text: string | undefined) => void;
}

interface ILazyTextFieldState {
  inputValue?: string;
}

class LazyTextField extends Component<ILazyTextFieldProps, ILazyTextFieldState> {
  constructor(props: ILazyTextFieldProps) {
    super(props);

    this.state = {
      inputValue: this.props.value
    };
  }

  public render() {
    return <TextField {...this.props} value={this.state.inputValue} onChange={this.onChange} onBlur={this.onBlur} />;
  }

  private onChange = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    this.setState({ inputValue: value });
    this.triggerDeferredTextChangeEvent();
  };

  /* tslint:disable-next-line */
  private triggerDeferredTextChangeEvent = debounce(() => {
    this.props.onTextChange(this.state.inputValue);
  }, 200);

  private onBlur = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.onTextChange(this.state.inputValue);
  };
}

export default LazyTextField;
