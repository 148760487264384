import React, { Component } from 'react';
import { TagPicker, ITag, IBasePicker } from 'office-ui-fabric-react/lib-commonjs/Pickers';
import { NumericDictionary } from 'lodash';

interface IApplicationPickerProps {
  id?: string;
  disabled?: boolean;
  applicationIds: string[] | undefined;
  onChange?: (applicationIds: string[]) => void;
  availableApplications: ITag[];
  applicationCountLimit: number;
}

export class ApplicationPicker extends Component<IApplicationPickerProps, {}> {
  private tagPickerReference = React.createRef<IBasePicker<ITag>>();

  constructor(props: IApplicationPickerProps) {
    super(props);
  }

  public render = () => (
    <TagPicker
      componentRef={this.tagPickerReference}
      disabled={this.props.disabled}
      selectedItems={(this.props.applicationIds || []).map(appId => ({ key: appId, name: this.getAppName(appId) }))}
      onResolveSuggestions={this.onResolveSuggestions}
      getTextFromItem={this.onGetTextFromItem}
      onChange={this.onApplicationListChange}
      pickerSuggestionsProps={{
        suggestionsHeaderText: 'Suggested Applications',
        noResultsFoundText: this.canAddApplication() ? 'No Applications Found' : 'You have reached the application count limit'
      }}
      inputProps={{
        'aria-label': 'Application Picker',
        id: this.props.id
      }}
    />
  );

  public focus = () => this.tagPickerReference.current!.focusInput();

  private canAddApplication = () => this.props.applicationIds !== undefined && this.props.applicationIds.length < this.props.applicationCountLimit;

  private getAppName = (appId: string) => this.props.availableApplications.filter(app => app.key === appId)[0].name;

  private onGetTextFromItem = (item: ITag) => item.name;

  private onResolveSuggestions = (filterText: string, existingApplications?: ITag[]): ITag[] =>
    this.getAppSuggestions(filterText, existingApplications || [], this.props.availableApplications);

  private onApplicationListChange = (items?: ITag[] | undefined) => {
    const appList = items ? items!.map(t => t.key) : [];
    if (this.props.onChange) {
      this.props.onChange(appList);
    }
  };

  private getAppSuggestions = (filterText: string, existingApplications: ITag[], availableApplications: ITag[]): ITag[] => {
    const filterTextTrimmed = (filterText || '').trim();
    if (!filterTextTrimmed || !this.canAddApplication()) {
      return [];
    }
    const filteredApplications = availableApplications.filter(app => app.name.toLowerCase().indexOf(filterTextTrimmed.toLowerCase()) >= 0);
    const andRemovedDuplicates = filteredApplications.filter(app => !this.isApplicationAlreadyOnTheList(app, existingApplications));
    return andRemovedDuplicates;
  };

  private isApplicationAlreadyOnTheList = (application: ITag, existingApplications: ITag[]) =>
    existingApplications.some(compareTag => compareTag.key.toLowerCase() === application.key.toLowerCase());
}
