import { Checkbox } from 'office-ui-fabric-react/lib-commonjs/Checkbox';
import { Label } from 'office-ui-fabric-react/lib-commonjs/Label';
import { TextField } from 'office-ui-fabric-react/lib-commonjs/TextField';
import React, { Component } from 'react';
import { UserLocation } from '../../../shared/api/models';
import ApplicationLocationSpecificUrls from '../ApplicationLocationSpecificUrls/ApplicationLocationSpecificUrls';

export interface IDetailedApplicationUrl {
  isActive: boolean;
  location?: UserLocation;
  url?: string;
}

interface IApplicationUrlsProps {
  isLocationSpecific: boolean;
  locationSpecificUrls: IDetailedApplicationUrl[];
  globalUrl: IDetailedApplicationUrl;
  onIsLocationSpecificChange: (isLocationSpecific: boolean) => void;
  onGlobalUrlChange: (url: IDetailedApplicationUrl) => void;
  onLocationSpecificUrlChange: (url: IDetailedApplicationUrl[]) => void;
}

class ApplicationUrls extends Component<IApplicationUrlsProps, any> {
  public render() {
    return (
      <div className="FormContent">
        <div className="FormRow">
          <Checkbox label="Location Specific?" checked={this.props.isLocationSpecific} onChange={this.onIsLocationSpecificChanged} />
        </div>
        {this.props.isLocationSpecific ? (
          <ApplicationLocationSpecificUrls urls={this.props.locationSpecificUrls} onChange={this.props.onLocationSpecificUrlChange} />
        ) : (
          <GlobalUrl detailedUrl={this.props.globalUrl} onChange={this.props.onGlobalUrlChange} />
        )}
      </div>
    );
  }

  private readonly onIsLocationSpecificChanged = (_?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    this.props.onIsLocationSpecificChange(isChecked || false);
  };
}

const GlobalUrl = (props: { detailedUrl: IDetailedApplicationUrl; onChange: (url: IDetailedApplicationUrl) => void }) => {
  const onChangeHandler = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, url?: string) => {
    props.onChange({ ...props.detailedUrl, url: url || '' });
  };

  return (
    <div className="FormRow">
      <Label required={true} htmlFor="ApplicationUrl">
        URL
      </Label>
      <TextField id="ApplicationUrl" value={props.detailedUrl.url} autoComplete="off" maxLength={2000} onChange={onChangeHandler} />
    </div>
  );
};

export default ApplicationUrls;
