import { Component, default as React } from 'react';
import { Coachmark } from 'office-ui-fabric-react/lib-commonjs/Coachmark';
import { TeachingBubbleContent } from 'office-ui-fabric-react/lib-commonjs/TeachingBubble';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { EventBus, EventNames } from '../../../shared/eventbus/eventbus';
import * as Tour from '../../../shared/api/tour/tour';
import Dialog, { DialogFooter, DialogType } from 'office-ui-fabric-react/lib-commonjs/Dialog';
import { AppTourNewStates } from '../../../shared/api/models';
import { featuresToShow } from './FeaturesToShow';
import { AppTourEventNames } from './IFeatureToShow';
import { AppTabKeys } from '../../routes/MainApp/MainApp';

interface IAppTourProps {
  activeTab: AppTabKeys;
}

interface IAppTourState {
  isInitialDialogVisible: boolean;
  isTourOn: boolean;
  currentFeatureIndex: number;
  isCoachMarkVisible: boolean;
}

export class AppTour extends Component<IAppTourProps, IAppTourState> {
  public constructor(props: IAppTourProps) {
    super(props);

    this.state = {
      isInitialDialogVisible: false,
      isTourOn: false,
      currentFeatureIndex: 0,
      isCoachMarkVisible: true
    };
  }

  public componentDidMount = () => {
    EventBus.subscribe(EventNames.MainApplicationLoaded, this.onMainAppLoaded);
    EventBus.subscribe(EventNames.StartApplicationTour, this.showApplicationTourDialog);
    EventBus.subscribe(AppTourEventNames.ShowNextFeature, this.showNextFeature);
  };

  public componentWillUnmount = () => {
    EventBus.unsubscribe(EventNames.MainApplicationLoaded, this.onMainAppLoaded);
    EventBus.unsubscribe(EventNames.StartApplicationTour, this.showApplicationTourDialog);
    EventBus.unsubscribe(AppTourEventNames.ShowNextFeature, this.showNextFeature);
    this.dismountCurrentFeature();
  };

  public render = () => (
    <>
      <Dialog
        hidden={!this.state.isInitialDialogVisible}
        maxWidth={600}
        dialogContentProps={{
          type: DialogType.normal,
          styles: { subText: { fontSize: '1rem' } },
          title: 'BCE Apps Screen Tour',
          subText:
            `Welcome to the BCE applications directory. Would you like to view a screen tour to learn about the features of this system? There are ${featuresToShow.length} key features we would like to show you.`
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        <DialogFooter>
          <PrimaryButton style={{ marginTop: '0.5rem' }} onClick={this.hideDialogAndShowFirstFeature} text="Yes, let's start" />
          <DefaultButton style={{ marginTop: '0.5rem' }} onClick={this.hideDialogAndShowNextTime} text="Maybe next time" />
          <DefaultButton style={{ marginTop: '0.5rem' }} onClick={this.hideDialogAndDismissTheTour} text="No thanks" />
        </DialogFooter>
      </Dialog>
      {this.state.isTourOn && (
        <>
          {this.state.isCoachMarkVisible && (
            <Coachmark
              color={'#8857A4'}
              beaconColorOne={'#8857A4'}
              beaconColorTwo={'#8857A4'}
              delayBeforeMouseOpen={1500}
              isPositionForced={false}
              target={this.getCurrentFeature().getSelector({ activeTab: this.props.activeTab })}
              positioningContainerProps={{
                directionalHint: this.getCurrentFeature().directionalHint
              }}
              onAnimationOpenEnd={this.animationEnd}
            >
              <TeachingBubbleContent
                headline={`${this.getCurrentFeature().title} (${this.state.currentFeatureIndex + 1}/${featuresToShow.length})`}
                hasCloseIcon={true}
                closeButtonAriaLabel="Close"
                primaryButtonProps={
                  this.getCurrentFeature().hideNextButton === true
                    ? undefined
                    : {
                        text: this.isNextFeatureAvailable() ? 'Next' : 'Finish',
                        onClick: this.showNextFeature
                      }
                }
                onDismiss={this.stopTheTour}
              >
                <span style={{ fontSize: '1rem' }}>{this.getCurrentFeature().description}</span>
              </TeachingBubbleContent>
            </Coachmark>
          )}
        </>
      )}
    </>
  );

  private animationEnd = () => {
    // Fix Safari render issue
    if (navigator.vendor.indexOf('Apple') > -1) {
      let timerCallbackCount = 0;
      const timer = setInterval(() => {
        timerCallbackCount++;
        if (timerCallbackCount > 6) {
          clearInterval(timer);
          return;
        }
        const element = document.querySelector('.ms-TeachingBubble-bodycontent') as any;
        if (element) {
          element.style.margin = timerCallbackCount % 2 === 0 ? '.001rem' : '.0rem'
        }
      }, 500);
    }
  };

  private getCurrentFeature = () => featuresToShow[this.state.currentFeatureIndex];

  private hideDialogAndShowFirstFeature = () => {
    this.setState({
      isInitialDialogVisible: false,
      isTourOn: true,
      currentFeatureIndex: 0
    });
    this.mountCurrentFeature();
  };

  private hideDialogAndShowNextTime = () => {
    this.setState({ isInitialDialogVisible: false });
    Tour.update({ requestedState: AppTourNewStates.ShowNextTime });
  };

  private hideDialogAndDismissTheTour = () => {
    this.setState({ isInitialDialogVisible: false });
    Tour.update({ requestedState: AppTourNewStates.Dismiss });
  };

  private onMainAppLoaded = async () => {
    const result = await Tour.get();
    if (result.success && result.result!.showAppTour) {
      this.showApplicationTourDialog();
    }
  };

  private showApplicationTourDialog = () => {
    this.setState({
      currentFeatureIndex: 0,
      isTourOn: false,
      isInitialDialogVisible: true
    });
  };

  private isNextFeatureAvailable = () => this.state.currentFeatureIndex + 1 < featuresToShow.length;

  private showNextFeature = () => {
    this.dismountCurrentFeature();
    if (this.isNextFeatureAvailable()) {
      this.setState({ isTourOn: false });
      setTimeout(() => {
        this.setState({
          isCoachMarkVisible: true,
          isTourOn: true,
          currentFeatureIndex: this.state.currentFeatureIndex + 1
        });
        this.mountCurrentFeature();
      }, 0);
    } else {
      this.setState({ isTourOn: false });
      Tour.update({ requestedState: AppTourNewStates.Dismiss });
    }
  };

  private stopTheTour = () => {
    this.setState({ isTourOn: false });
    this.dismountCurrentFeature();
  };

  private mountCurrentFeature = () => {
    const currentFeature = this.getCurrentFeature();
    if (currentFeature.featureDidShowUp) {
      currentFeature.featureDidShowUp({ activeTab: this.props.activeTab });
    }
  };

  private dismountCurrentFeature = () => {
    const currentFeature = this.getCurrentFeature();
    if (currentFeature.featureWillHide) {
      currentFeature.featureWillHide();
    }
  };
}
