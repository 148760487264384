import React, { Component } from 'react';
import './NotFound.scss';

class NotFound extends Component<any> {
  public render() {
    return (
      <div className="notFound">
        <h1 className="ms-fontColor-orangeLighter">404 - Page not Found</h1>
      </div>
    );
  }
}

export default NotFound;
