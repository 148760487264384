import { logInfo } from '../logging/logging';

export interface IConfig {
  environment: string;
  isWidget: boolean;
  apiBaseUrl: string;
  aadTenantId: string;
  aadAppId: string;
  mainAppUrl: string;
  loginHint: string;
  searchAllSitesUrlTemplate: string;
  applicationInsightsInstrumentationKey: string;
  loginReturnUrl: string;
}

let config: IConfig = {
  environment: '',
  isWidget: false,
  apiBaseUrl: '',
  aadTenantId: '',
  aadAppId: '',
  mainAppUrl: '',
  loginHint: '',
  searchAllSitesUrlTemplate: '',
  applicationInsightsInstrumentationKey: '',
  loginReturnUrl:''
};

// In the widget, we can't refer to a relative file because we are being embedded in another app's html. Instead
// we use an absolute URL to configuration, which is setup in Azure DevOps.
const configPath = process.env.REACT_APP_WIDGET && process.env.NODE_ENV !== 'development' ? '#{Client.ConfigUrl}#' : '/config.json';

const fetchConfig = async (): Promise<IConfig> => {
  if (config.environment) {
    return config;
  }

  if (process.env.NODE_ENV === 'test') {
    config.environment = 'test';
    return config;
  }

  const json = await loadTextFileAjax(configPath, 'application/json');

  config = JSON.parse(json);

  // if in DEV mode, try loading a local file with specific settings
  if (process.env.NODE_ENV === 'development') {
    try {
      const jsonLocal = await loadTextFileAjax('/config.local.json', 'application/json');
      const configLocal: IConfig = JSON.parse(jsonLocal);
      config = { ...config, ...configLocal };
    } catch (e) {
      logInfo('config.local.json is missing but this is fine.');
    }
  }

  config.isWidget = !!process.env.REACT_APP_WIDGET;
  return config;
};

const getConfig = (): IConfig => {
  if (!config.environment) {
    throw new Error('Tried to get config, but it was not fetched. Make sure fetchConfig() is called before any other code.');
  }

  return config;
};

const makeSearchAllSitesUrl = (searchPhrase: string) => getConfig().searchAllSitesUrlTemplate.replace('%QUERY%', encodeURIComponent(searchPhrase));

const loadTextFileAjax = (filePath: string, mimeType: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath);
    if (xhr.overrideMimeType) {
      xhr.overrideMimeType(mimeType);
    }
    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.send();
  });
};

export { fetchConfig, getConfig, makeSearchAllSitesUrl };
