import { DirectionalHint } from 'office-ui-fabric-react/lib-commonjs/common/DirectionalHint';
import { EventBus } from '../../../shared/eventbus/eventbus';
import { AppTabKeys } from '../../routes/MainApp/MainApp';

export interface IFeatureHost {
  activeTab: AppTabKeys;
}

export interface IFeatureToShow {
  getSelector: (host: IFeatureHost) => string | HTMLElement | null;
  directionalHint: DirectionalHint;
  title: string;
  description: string;
  unblockInteractionWithTheAppAfterCoachmarkOpens?: boolean;
  hideNextButton?: boolean;
  featureDidShowUp?: (host: IFeatureHost) => void;
  featureWillHide?: () => void;
}

export const MaximumLargeScreenWidth = 1023;
export const MinimumLargeScreenWidth = 640;

export const MinimumMediumScreenWidth = 480;

export enum AppTourEventNames {
  ShowNextFeature = 'ShowNextFeature'
}

export const showNextFeature = () => EventBus.emit(AppTourEventNames.ShowNextFeature);
