import React, { Component } from 'react';
import LoadingPanel from '../../../shared/components/LoadingPanel/LoadingPanel';
import { Label } from 'office-ui-fabric-react/lib-commonjs/Label';
import { WorkTracker } from '../../../shared/components/LoadingPanel/work-tracker';
import SaveButton from '../SaveButton/SaveButton';
import { DefaultButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import * as DefaultProfileApps from '../../../shared/api/default-apps/default-apps';
import { toast } from 'react-toastify';
import { ProfileDefaultAppsDto, ProfileDto, ApplicationDto } from '../../../shared/api/models';
import * as Profiles from '../../../shared/api/profiles/profiles';
import * as Applications from '../../../shared/api/applications/applications';
import * as Configuration from '../../../shared/api/configuration/configuration';
import { orderBy } from 'lodash';
import { ApplicationPicker } from './ApplicationPicker';

interface IAdminDefaultProfileAppsState {
  isSubmitting: boolean;
  isInEditMode: boolean;
  defaultProfileApps: ProfileDefaultAppsDto[];
  availableProfiles: ProfileDto[];
  availableApplications: ApplicationDto[];
  sideDrawAppCountLimit: number;
  workTracker: WorkTracker;
}

class AdminDefaultProfileApps extends Component<any, IAdminDefaultProfileAppsState> {
  private applicationPickerReference = React.createRef<ApplicationPicker>();

  constructor(props: any) {
    super(props);
    this.state = {
      isSubmitting: false,
      isInEditMode: false,
      defaultProfileApps: [],
      availableProfiles: [],
      availableApplications: [],
      sideDrawAppCountLimit: 0,
      workTracker: new WorkTracker()
    };
  }

  public render = () => (
    <LoadingPanel workTracker={this.state.workTracker}>
      <DefaultButton text="Edit" onClick={this.enterEditMode} disabled={this.state.isInEditMode} />
      <form className="Form" style={{ marginTop: '1rem' }}>
        {this.state.defaultProfileApps &&
          this.state.defaultProfileApps.map((apps, index) => (
            <div className="FormRow" key={apps.profileCode}>
              <Label htmlFor={`Applications_${apps.profileCode}`}>
                {this.state.availableProfiles.filter(p => p.profileCode === apps.profileCode)[0].displayName}
              </Label>
              <ApplicationPicker
                ref={index === 0 ? this.applicationPickerReference : undefined}
                id={`Applications_${apps.profileCode}`}
                disabled={!this.state.isInEditMode}
                applicationCountLimit={this.state.sideDrawAppCountLimit}
                applicationIds={apps.defaultAppIds}
                availableApplications={this.state.availableApplications.map(app => ({ key: app.id, name: app.name }))}
                onChange={this.listOfApplicationsChanged(apps.profileCode)}
              />
            </div>
          ))}
        <div className="FormFooter">
          {this.state.isInEditMode && (
            <>
              <SaveButton isSubmitting={this.state.isSubmitting} isFormValid={this.isFormValid()} submitForm={this.submitForm} />
              <DefaultButton text="Cancel" onClick={this.leaveEditMode} style={{ marginLeft: '0.5rem' }} />
            </>
          )}
        </div>
      </form>
    </LoadingPanel>
  );

  public componentDidMount = async () => {
    this.state.workTracker.track(this.loadData());
  };

  public componentDidUpdate = (prevProps: any, prevState: IAdminDefaultProfileAppsState) => {
    if (!prevState.isInEditMode && this.state.isInEditMode) {
      if (this.applicationPickerReference.current !== null) {
        this.applicationPickerReference.current!.focus();
      }
    }
  };

  private leaveEditMode = () => {
    this.setState({ isInEditMode: false });
    this.state.workTracker.track(this.loadData());
  };

  private enterEditMode = () => {
    this.setState({ isInEditMode: true });
  };

  private loadData = async () => {
    const [defaultProfileAppsResponse, profilesResponse, applicationsResponse, configurationResult] = await Promise.all([
      DefaultProfileApps.getMany(),
      Profiles.getAll(),
      Applications.getMany(true),
      Configuration.get()
    ]);

    if (defaultProfileAppsResponse.success && profilesResponse.success && applicationsResponse.success && configurationResult.success) {
      const defaultProfileApps = defaultProfileAppsResponse.result!;
      const availableProfiles = orderBy(profilesResponse.result!, 'displayName', 'asc');
      const availableApplications = orderBy(applicationsResponse.result!, 'name', 'asc');
      const filteredDefaultAppsByAvailableProfiles = defaultProfileApps.filter(
        apps => availableProfiles.map(p => p.profileCode).indexOf(apps.profileCode) >= 0
      );
      const sideDrawAppCountLimit = configurationResult.result!.sideDrawAppCountLimit;
      this.setState({ availableProfiles, availableApplications, defaultProfileApps: filteredDefaultAppsByAvailableProfiles, sideDrawAppCountLimit });
    }
  };

  private isFormValid = () => this.state.defaultProfileApps.every(p => !!p.defaultAppIds && !!p.defaultAppIds.length);

  private submitForm = async (): Promise<boolean> => {
    this.setState({ isSubmitting: true });
    const response = await DefaultProfileApps.updateMany(this.state.defaultProfileApps);
    this.setState({ isSubmitting: false });
    if (response.success) {
      toast.success('Default profile apps were successfully saved');
      this.setState({ isInEditMode: false });
    }
    return response.success;
  };

  private listOfApplicationsChanged = (profileCode: string) => (applicationIds: string[]) => {
    const defaultProfileApps = this.state.defaultProfileApps.map(apps => {
      if (apps.profileCode === profileCode) {
        return { profileCode, defaultAppIds: applicationIds };
      }
      return apps;
    });
    this.setState({ defaultProfileApps });
  };
}

export default AdminDefaultProfileApps;
