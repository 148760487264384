export enum EventNames {
  FavouriteAppListModified = 'FavouriteAppListModified',
  MainApplicationLoaded = 'MainApplicationLoaded',
  StartApplicationTour = 'StartApplicationTour',
  UserOpenedSideDraw = 'UserOpenedSideDraw',
  HideSideDraw = 'HideSideDraw',
  OpenSideDraw = 'OpenSideDraw',
  MakeSureGlobalSearchIsVisible = 'MakeSureGlobalSearchIsVisible',
  MakeSureHelpIsVisible = 'MakeSureHelpIsVisible'
}

type EventHandler = (data: any) => void;

export class EventBus {
  public static subscribe(eventName: string, eventHandler: EventHandler) {
    EventBus.eventHandlers[eventName] = EventBus.eventHandlers[eventName] || [];
    EventBus.eventHandlers[eventName].push(eventHandler);
  }

  public static unsubscribe(eventName: string, eventHandler: EventHandler) {
    if (EventBus.eventHandlers[eventName]) {
      EventBus.eventHandlers[eventName] = EventBus.eventHandlers[eventName].filter(existingHandler => existingHandler !== eventHandler);
    }
  }

  public static emit(eventName: string, data?: any) {
    if (EventBus.eventHandlers[eventName]) {
      EventBus.eventHandlers[eventName].forEach(eventHandler => eventHandler(data));
    }
  }

  private static eventHandlers: { [eventName: string]: EventHandler[] } = {};
}
